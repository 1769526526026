// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import RpcClient from './rpc/client';
import LocalStorage from './local-storage';

// A user must register an account to authenticate with the server. The user's identity is defined by her email address and the account is password-protected.
// Typically, a verification email is sent upon registration. Note that the administrator whitelists the emails that can register.
// On desktop and mobile, the user may associate the account with a device using DeviceCollection.
class AccountCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Register registers a new account on a server.
  // Use InviteCollection's Register() to register an invited user.
  register(serverUrl, email, password, givenName, surname) {
    return this._rpc.call("account.register", [serverUrl, email, password, givenName, surname]);
  }

  // ValidateEmail validates the registration with the token received by email.
  validateEmail(serverUrl, token) {
    return this._rpc.call("account.validateEmail", [serverUrl, token]);
  }

  // GetEmailUpdateInfo returns the information on an email update request.
  // The user must be authenticated.
  getEmailUpdateInfo(token) {
    return this._rpc.call("account.getEmailUpdateInfo", [token]);
  }

  // ValidateEmailUpdate validates an email update request.
  // The parameter `newEmail` is the new email address retrieved with AccountCollection's GetEmailUpdateInfo() and validated by the user.
  // The user must be password-authenticated.
  validateEmailUpdate(token, newEmail) {
    return this._rpc.call("account.validateEmailUpdate", [token, newEmail]);
  }

  // ResendValidationEmail requests the server to send the validation email again.
  resendValidationEmail(serverUrl, email) {
    return this._rpc.call("account.resendValidationEmail", [serverUrl, email]);
  }

  // List returns the accounts saved on the device.
  // See DeviceCollection's Add().
  // See DeviceCollection's Login().
  // See AccountCollection's Remove().
  list() {
    return this._rpc.call("account.list", []);
  }

  // WatchAll registers an observer on the result of AccountCollection's List().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchAll(obs) {
    return this._rpc.call("account.watchAll", [this._rpc.proxy(obs)]);
  }

  // Get retrieves one of the account saved on the device.
  get(id) {
    return this._rpc.call("account.get", [id]);
  }

  // GetCurrent returns the account of the authenticated user.
  getCurrent() {
    return this._rpc.call("account.getCurrent", []);
  }

  // WatchCurrent registers an observer on the result of AccountCollection's GetCurrent().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchCurrent(obs) {
    return this._rpc.call("account.watchCurrent", [this._rpc.proxy(obs)]);
  }

  // Deprecated. Use AccountCollection's GetCurrent(), SpaceCollection's List(), UserStatusCollection's Get(), TrusteeCollection's List() and CategoryCollection's List().
  getInfo() {
    return this._rpc.call("account.getInfo", []);
  }

  // ChangePassword updates the account's password of the authenticated user.
  // The server may request an extra authentication; see UserAgent's SetSecondFactorAuthenticator().
  changePassword(newPassword, currentPassword) {
    return this._rpc.call("account.changePassword", [newPassword, currentPassword]);
  }

  // Remove removes an account from the device and deletes its cryptographic keys.
  // The method does not revoke the associated device.
  // See DeviceCollection's Add().
  remove(id) {
    return this._rpc.call("account.remove", [id]);
  }

}

// Provides the methods to read and write the settings of the authenticated user.
class AccountSettingsCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Get returns the settings for the authenticated user.
  get() {
    return this._rpc.call("accountSettings.get", []);
  }

  // SetFileChangeEmailNotification updates the email frequency setting for the authenticated user.
  setFileChangeEmailNotification(on) {
    return this._rpc.call("accountSettings.setFileChangeEmailNotification", [on]);
  }

}

// The user may attach files to instant messages sent with MessageCollection.
// Previews are automatically computed for images.
// Attached files and previews are uploaded into the space as regular files.
class AttachmentCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Add creates an attachment.
  add(messageId, source, options) {
    return this._rpc.call("attachment.add", [messageId, this._rpc.proxy(source), options]);
  }

  // AddFromDisk creates an attachment from a local file.
  addFromDisk(messageId, source, options) {
    return this._rpc.call("attachment.addFromDisk", [messageId, source, options]);
  }

  // DownloadPreview creates or downloads the preview for the attachment.
  // If `width` is <= 0, the method returns the raw preview. Otherwise the preview is resized to match `width` and blurred for preview generated before v4.17.
  // The preview is a JPEG image. The raw preview is of a few Ko.
  // The message may have any status.
  downloadPreview(id, width, target) {
    return this._rpc.call("attachment.downloadPreview", [id, width, this._rpc.proxy(target)]);
  }

  // DownloadPreviewToTemp creates or downloads the preview for the attachment and write the result to disk.
  // If `width` is <= 0, the method returns the raw preview. Otherwise the preview is resized and blured to match `width`.
  // The preview is a JPEG image. The raw preview is of a few Ko.
  // The message may have any status.
  downloadPreviewToTemp(id, width) {
    return this._rpc.call("attachment.downloadPreviewToTemp", [id, width]);
  }

  // Download downloads an attachment.
  // The message must be delivered.
  download(id, target) {
    return this._rpc.call("attachment.download", [id, this._rpc.proxy(target)]);
  }

  // DownloadToDisk downloads an attachment to disk.
  downloadToDisk(id, target) {
    return this._rpc.call("attachment.downloadToDisk", [id, target]);
  }

  // DownloadToTemp downloads an attachment to a temp folder on disk.
  // The message must be delivered.
  downloadToTemp(id) {
    return this._rpc.call("attachment.downloadToTemp", [id]);
  }

  // GetContentInfo returns the information to download and decrypt an attachment.
  // The method raises an error if the antivirus is enabled, to prevent the client to bypass it.
  getContentInfo(id) {
    return this._rpc.call("attachment.getContentInfo", [id]);
  }

  // GetDefaultPreviewSize returns the size in pixels of the largest side of the preview image.
  // This is a configuration value, you should only use it to provide the preview yourself.
  // See AttachmentCollection's Add() and AttachmentCollection's AddFromDisk().
  getDefaultPreviewSize() {
    return this._rpc.call("attachment.getDefaultPreviewSize", []);
  }

}

// Path breacrumb utility.
class BreadcrumbCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Get returns the deepest existing folder on a given path.
  // You may ensure your current location in the file tree is still valid using this method or BreadcrumbCollection's Watch().
  get(spaceId, revision, path) {
    return this._rpc.call("breadcrumb.get", [spaceId, revision, path]);
  }

  // Watch registers an observer on the result of BreadcrumbCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(spaceId, revision, path, obs) {
    return this._rpc.call("breadcrumb.watch", [spaceId, revision, path, this._rpc.proxy(obs)]);
  }

}

// CacheModule exposes the toolbox cache to the client application.
// 
// On desktop and mobile, the cache is persisted to disk.
// 
// The module is experimental.
class CacheModule {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // GetServerSettings returns the server settings from the cache.
  // The method raises ErrorCodeNotFound if the settings are not in cache.
  getServerSettings(options) {
    return this._rpc.call("cache.getServerSettings", [options]);
  }

}

// The user may create categories to organize the spaces she belongs to.
// The categories are stored in the user account and only visible to the user.
class CategoryCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Create creates a category.
  create(name) {
    return this._rpc.call("category.create", [name]);
  }

  // SetName updates the name of a category.
  // The change won't be visible on clients before v4.29.
  setName(categoryId, name) {
    return this._rpc.call("category.setName", [categoryId, name]);
  }

  // List returns the categories for the authenticated user.
  list() {
    return this._rpc.call("category.list", []);
  }

  // WatchAll registers an observer on the result of CategoryCollection's List().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchAll(obs) {
    return this._rpc.call("category.watchAll", [this._rpc.proxy(obs)]);
  }

  // Get returns a category.
  get(id) {
    return this._rpc.call("category.get", [id]);
  }

  // Watch registers an observer on the result of CategoryCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(id, obs) {
    return this._rpc.call("category.watch", [id, this._rpc.proxy(obs)]);
  }

  // Update adds and removes relationships between spaces and categories.
  // The method execute all the changes in one HTTP request.
  update(add, remove) {
    return this._rpc.call("category.update", [add, remove]);
  }

  // Deprecated. Use CategoryCollection's Create().
  create_(name) {
    return this._rpc.call("category.create_", [name]);
  }

  // Deprecated. Use CategoryCollection's List().
  list_() {
    return this._rpc.call("category.list_", []);
  }

  // AddSpace adds a space to a category.
  addSpace(categoryId, spaceId) {
    return this._rpc.call("category.addSpace", [categoryId, spaceId]);
  }

  // AddSpaces add multiple spaces at once to a category.
  addSpaces(categoryId, spaceIds) {
    return this._rpc.call("category.addSpaces", [categoryId, spaceIds]);
  }

  // RemoveSpace removes a space from a category.
  removeSpace(categoryId, spaceId) {
    return this._rpc.call("category.removeSpace", [categoryId, spaceId]);
  }

  // RemoveSpaces removes multiple spaces at once from a category.
  removeSpaces(categoryId, spaceIds) {
    return this._rpc.call("category.removeSpaces", [categoryId, spaceIds]);
  }

  // SetSpaceCategories replaces the categories of a space.
  setSpaceCategories(spaceId, categoryIds) {
    return this._rpc.call("category.setSpaceCategories", [spaceId, categoryIds]);
  }

  // Deprecated. Use SpaceCollection's Get().
  getSpaceCategories(spaceId) {
    return this._rpc.call("category.getSpaceCategories", [spaceId]);
  }

  // Deletes a category.
  delete(id) {
    return this._rpc.call("category.delete", [id]);
  }

  // Returns the ID of the favorites category.
  getFavoritesCategoryId() {
    return this._rpc.call("category.getFavoritesCategoryId", []);
  }

}

// Cryptographic utils.
class CryptoModule {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Export the ASN.1 PKIX DER of the public key.
  // In ECDSA, the key size is the curve identifier (by e.g. 256 for secp256r1).
  // Adapted from https://golang.org/src/crypto/x509/x509.go.
  exportPublicKeyToDer(keyType, publicKeyBytes, keySize) {
    return this._rpc.call("crypto.exportPublicKeyToDer", [keyType, publicKeyBytes, keySize]);
  }

  // Export the ASN.1 DER of a raw ECDSA signature.
  exportEcdsaSignatureToDer(rawSig) {
    return this._rpc.call("crypto.exportEcdsaSignatureToDer", [rawSig]);
  }

  // Removes the OAEP padding from data, provided the key size in bits.
  // Adapted from https://golang.org/src/crypto/rsa/rsa.go.
  unpadOaep(data, keySize) {
    return this._rpc.call("crypto.unpadOaep", [data, keySize]);
  }

  // Run tests on the device keys.
  checkDeviceKeys() {
    return this._rpc.call("crypto.checkDeviceKeys", []);
  }

}

// DepositBoxModule provides the methods to create, list, update and delete deposit boxes.
// 
// The feature availability may be checked with DepositBoxModule's CanAccess().
// 
// With the CLI, the submitter should call DepositBoxModule's Submit() to upload files. On other platforms, see DepositBoxFormModule.
class DepositBoxModule {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Create creates a new deposit box.
  // The user must be authenticated.
  // 
  // See DepositBoxModule's CanAccess().
  create(name, options) {
    return this._rpc.call("depositBox.create", [name, options]);
  }

  // List returns a summary of the deposit boxes owned by the authenticated user.
  // See DepositBoxModule's Get() to retrieve the details of a deposit box.
  // The user must be authenticated.
  // 
  // See DepositBoxModule's CanAccess().
  list() {
    return this._rpc.call("depositBox.list", []);
  }

  // Get returns the detailed information on a deposit box.
  // See DepositBoxModule's List() to retrieve the list of deposit boxes.
  // The user must be authenticated.
  // 
  // See DepositBoxModule's CanAccess().
  get(id) {
    return this._rpc.call("depositBox.get", [id]);
  }

  // Download downloads files from a deposit box.
  // The method can:
  // - Download a file to a file.
  // - Download multiple files as a ZIP archive to a file.
  // - Download one or multiple files to a folder.
  // 
  // If the target file does not exists, it will be created.
  // 
  // The parameter `target` must be a string (path) or an OutputFile. Paths are not supported on the web.
  // 
  // The user must be authenticated and own the deposit box.
  // 
  // See DepositBoxModule's CanAccess().
  download(id, filenames, target, options) {
    if (typeof target == "string") {
      target = {"string": target}
    }
    if (target && typeof target.write == "function") {
      target = {"OutputFile": this._rpc.proxy(target)}
    }
    return this._rpc.call("depositBox.download", [id, filenames, target, options]);
  }

  // Copy copies files from a deposit box to a space.
  // The method can:
  // - Copy a single file to the specified path. If the target path exists, it must be a file and will be replaced. If it does not, it will be created.
  // - Copy multiple files to a directory. The target path must exists and be a directory.
  // 
  // The user must be authenticated, own the deposit box and have write rights in the space.
  // 
  // See DepositBoxModule's CanAccess().
  copy(id, filenames, spaceId, target) {
    return this._rpc.call("depositBox.copy", [id, filenames, spaceId, target]);
  }

  // Update updates the information of a deposit box.
  // The user must be authenticated and own the deposit box.
  // 
  // See DepositBoxModule's CanAccess().
  update(id, options) {
    return this._rpc.call("depositBox.update", [id, options]);
  }

  // Delete deletes a deposit box.
  // The user must be authenticated and own the deposit box.
  // 
  // See DepositBoxModule's CanAccess().
  delete(id) {
    return this._rpc.call("depositBox.delete", [id]);
  }

  // CanAccess returns whether the authenticated user has access to the deposit boxes.
  // If true, all the operations are authorized.
  // 
  // This permission depends on the server settings and the license pool of the user.
  canAccess() {
    return this._rpc.call("depositBox.canAccess", []);
  }

}

// DepositBoxFormModule provides the methods to upload and commit files to a deposit box.
// 
// The submitter:
// - Opens a deposit form with DepositBoxFormModule's Get().
// - Adds files with DepositBoxFormModule's Upload().
// - Optionaly removes files with DepositBoxFormModule's Remove() and DepositBoxFormModule's RemoveAll().
// - Submits the form with DepositBoxFormModule's Submit().
// 
// The submitter does not need to be authenticated.
class DepositBoxFormModule {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Test checks the validity of a deposit box link URL.
  // The method returns an error if the URL is malformed or if the server does not know the box.
  // The method does not verify the code or the hash.
  // See DepositBoxFormModule's Get().
  test(linkUrl) {
    return this._rpc.call("depositBoxForm.test", [linkUrl]);
  }

  // Get retreives a deposit box form.
  // The form is only valid until it is submitted, the user logs out or the process is terminated.
  // The form ID is chosen randomly each time this method is called. In particular, it is *not* the deposit box ID.
  // The method returns ErrorCodeBadDepositBoxCode if the code is wrong.
  // See DepositBoxFormModule's Test().
  get(linkUrl, options) {
    return this._rpc.call("depositBoxForm.get", [linkUrl, options]);
  }

  // Upload attaches a file to the deposit box form and uploads its content to the server.
  // The file is not visible to the owner of the the deposit box until DepositBoxFormModule's Submit() is called.
  // The form ID must have been obtained with DepositBoxFormModule's Get().
  // The parameter `source` must be a string (path) or an InputFile. Paths are not supported on the web.
  // The method returns an error if `source` is a directory.
  upload(id, source) {
    if (typeof source == "string") {
      source = {"string": source}
    }
    if (source && typeof source.name == "function" && typeof source.size == "function" && typeof source.read == "function") {
      source = {"InputFile": this._rpc.proxy(source)}
    }
    return this._rpc.call("depositBoxForm.upload", [id, source]);
  }

  // Remove cancels an upload and detaches the file from the deposit box form.
  // The form ID must have been obtained with DepositBoxFormModule's Get().
  remove(id, name) {
    return this._rpc.call("depositBoxForm.remove", [id, name]);
  }

  // RemoveAll cancels all the uploads and detaches all the files from the deposit box form.
  // The form ID must have been obtained with DepositBoxFormModule's Get().
  removeAll(id) {
    return this._rpc.call("depositBoxForm.removeAll", [id]);
  }

  // Submit waits for the file uploads to complete and commits them to the deposit box.
  // If any of the upload fails or has failed, the method returns an error. See DepositBoxFormModule's Remove() and DepositBoxFormModule's RemoveAll().
  // The form ID must have been obtained with DepositBoxFormModule's Get().
  // On success, the form ID becomes invalid.
  // The method returns ErrorCodeBadDepositBoxCode if the code is wrong.
  submit(id, options) {
    return this._rpc.call("depositBoxForm.submit", [id, options]);
  }

}

// On desktop and mobile, the user may store cryptographic keys on the device to authenticate with the server in place of her password.
class DeviceCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Add adds a new device to the account of the authenticated user.
  // The method creates new cryptographic keys and saves them, along with the account information, on the device.
  // On success, the user is logged in with the device and the account will appear in the account list.
  // See AccountCollection's List().
  // See AccountCollection's Remove().
  // See DeviceCollection's Login().
  add() {
    return this._rpc.call("device.add", []);
  }

  // List returns the devices of the authenticated user.
  list() {
    return this._rpc.call("device.list", []);
  }

  // WatchAll registers an observer on the result of DeviceCollection's List().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchAll(obs) {
    return this._rpc.call("device.watchAll", [this._rpc.proxy(obs)]);
  }

  // Get returns a device.
  get(id) {
    return this._rpc.call("device.get", [id]);
  }

  // Watch registers an observer on the result of DeviceCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(id, obs) {
    return this._rpc.call("device.watch", [id, this._rpc.proxy(obs)]);
  }

  // GetCurrent returns the device of the authenticated user.
  // The method fails if the user is not authenticated with DeviceCollection's Login() or DeviceCollection's Add().
  getCurrent() {
    return this._rpc.call("device.getCurrent", []);
  }

  // WatchCurrent registers an observer on the result of DeviceCollection's GetCurrent().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchCurrent(obs) {
    return this._rpc.call("device.watchCurrent", [this._rpc.proxy(obs)]);
  }

  // SetState changes the states of a device.
  // The device must belong to the authenticated user.
  setState(id, state) {
    return this._rpc.call("device.setState", [id, state]);
  }

  // Login authenticates the user with the device.
  // The method fails with EINVAL if `accountID` does not match any account on the device.
  // See DeviceCollection's Add().
  // See AccountCollection's List().
  login(accountId) {
    return this._rpc.call("device.login", [accountId]);
  }

  // Deprecated. Use DeviceCollection's Login().
  login_(serverUrl, email) {
    return this._rpc.call("device.login_", [serverUrl, email]);
  }

  // Deprecated. Use DeviceCollection's List().
  list_() {
    return this._rpc.call("device.list_", []);
  }

  // Deprecated. Use DeviceCollection's SetState().
  setState_(signingCertificate, state) {
    return this._rpc.call("device.setState_", [signingCertificate, state]);
  }

  // GetEncryptionKeyAlias returns the alias of the encryption key in the device keystore.
  // The user must be authenticated with device keys.
  getEncryptionKeyAlias() {
    return this._rpc.call("device.getEncryptionKeyAlias", []);
  }

}

// The server reports changes to the user agent via long polling. You may call EventCollection's WatchInbox() to observe these notifications.
// This is not the preferred way though, as the relationship between the notification and the change is not always obvious, and you should use the live queries instead (all the methods starting with "Watch", SpaceCollection's WatchAll by eg.). On the other hand, while the live queries are more practical, the long-polling is more lightweight.
// Also note that the user agent does subscribe to the long polling and updates most of its data automatically.
// See EventKind for the list of available notifications.
class EventCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Deprecated. Use LoginStatusCollection's Watch().
  addLoginObserver(obs) {
    return this._rpc.call("event.addLoginObserver", [this._rpc.proxy(obs)]);
  }

  // Deprecated. Use EventCollection's WatchInbox().
  addUpdateObserver(obs) {
    return this._rpc.call("event.addUpdateObserver", [this._rpc.proxy(obs)]);
  }

  // Deprecated.
  removeObserver(token) {
    return this._rpc.call("event.removeObserver", [token]);
  }

  // Inbox returns the last received events.
  // You should use EventCollection's WatchInbox() to be notified in a timely fashion.
  inbox() {
    return this._rpc.call("event.inbox", []);
  }

  // WatchInbox registers an observer on the result of EventCollection's Inbox().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchInbox(obs) {
    return this._rpc.call("event.watchInbox", [this._rpc.proxy(obs)]);
  }

  // RestartPolling restarts polling the server for low-level notifications.
  // The polling starts automatically after a successful login and stops automatically when the user agent is logged out.
  // The method does nothing if the user is logged out.
  restartPolling() {
    return this._rpc.call("event.restartPolling", []);
  }

  // StopPolling stops polling the server for low-level notifications.
  // The user agent will stop updating its data automatically until the next login or call to EventCollection's RestartPolling().
  stopPolling() {
    return this._rpc.call("event.stopPolling", []);
  }

}

// Files are stored in spaces and organized in regular directory trees.
// Any space member may read the files, only a "member" or a "owner" may write them.
// Consecutive versions of the whole file tree are called revisions and may be read back.
// See SpaceCollection, SpaceMemberCollection and FileRevisionCollection.
class FileCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // CreateDirectory creates a folder.
  // The authenticated user must be a member or an owner.
  createDirectory(spaceId, path) {
    return this._rpc.call("file.createDirectory", [spaceId, path]);
  }

  // Deprecated. Use FileCollection's CreateDirectory().
  createDirectory_(spaceId, dirname, basename) {
    return this._rpc.call("file.createDirectory_", [spaceId, dirname, basename]);
  }

  // Uploads a file.
  // If the target is folder, the method copies the source into the target.
  // If the target is a file, the method overwrites the target with the source. The method returns an error if the source is not a file.
  // If the target does not exist, the method creates the target and its parent folders recursively.
  // The user must have write rights for the space.
  upload(spaceId, path, source) {
    return this._rpc.call("file.upload", [spaceId, path, this._rpc.proxy(source)]);
  }

  // Uploads a file or a directory from disk.
  // If the target is folder, the method copies the source into the target.
  // If the target is a file, the method overwrites the target with the source. The method returns an error if the source is not a file.
  // If the target does not exist, the method creates the target and its parent folders recursively.
  // The user must have write rights for the space.
  uploadFromDisk(spaceId, path, source) {
    return this._rpc.call("file.uploadFromDisk", [spaceId, path, source]);
  }

  // List returns the files at a given path and revision.
  // If `revision` is <= 0, the latest revision is used.
  list(spaceId, revision, path) {
    return this._rpc.call("file.list", [spaceId, revision, path]);
  }

  // WatchAll registers an observer on the result of FileCollection's List().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchAll(spaceId, revision, path, obs) {
    return this._rpc.call("file.watchAll", [spaceId, revision, path, this._rpc.proxy(obs)]);
  }

  // Tree lists the files and folders recursively.
  tree(spaceId, options) {
    return this._rpc.call("file.tree", [spaceId, options]);
  }

  // WatchTree registers an observer on the result of FileCollection's Tree().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchTree(spaceId, options, obs) {
    return this._rpc.call("file.watchTree", [spaceId, options, this._rpc.proxy(obs)]);
  }

  // Deprecated. Use FileCollection's List().
  list_(spaceId, pathname, options) {
    return this._rpc.call("file.list_", [spaceId, pathname, options]);
  }

  // Get returns the file at a given path and revision.
  // If `revision` is <= 0, the latest revision is used.
  get(spaceId, revision, path) {
    return this._rpc.call("file.get", [spaceId, revision, path]);
  }

  // Watch registers an observer on the result of FileCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(spaceId, revision, path, obs) {
    return this._rpc.call("file.watch", [spaceId, revision, path, this._rpc.proxy(obs)]);
  }

  // Find returns the file with the given tracking ID.
  // The method returns ErrorCodeNotFound if the file does not exist.
  find(spaceId, trackingId, options) {
    return this._rpc.call("file.find", [spaceId, trackingId, options]);
  }

  // CreateLink returns the internal link to a file or folder.
  // The method creates a tracking ID for the path if missing.
  // The user must be authenticated and have write permission in the space. The tracking IDs must be enabled on the server.
  // See PermissionsRecord's CanCreateLink.
  createLink(spaceId, path) {
    return this._rpc.call("file.createLink", [spaceId, path]);
  }

  // Deprecated. Use FileCollection's Get().
  getInfo(spaceId, pathname) {
    return this._rpc.call("file.getInfo", [spaceId, pathname]);
  }

  // Search returns the files whose names contains a given pattern.
  // The search is case-insensitive and diacritic-insensitive. Glob patterns are not supported.
  // If `revision` < 1 the method browses the latest revision.
  search(spaceId, revision, searchTerm) {
    return this._rpc.call("file.search", [spaceId, revision, searchTerm]);
  }

  // WatchSearch registers an observer on the result of FileCollection's Search().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchSearch(spaceId, revision, searchTerm, obs) {
    return this._rpc.call("file.watchSearch", [spaceId, revision, searchTerm, this._rpc.proxy(obs)]);
  }

  // Exists returns whether a path exists.
  exists(spaceId, revision, path) {
    return this._rpc.call("file.exists", [spaceId, revision, path]);
  }

  // Deprecated. Use FileCollection's Exists().
  exists_(spaceId, pathname) {
    return this._rpc.call("file.exists_", [spaceId, pathname]);
  }

  // Copy copies files between spaces.
  // The method can copy a single file to an existing file or directory, or multiple files to an existing directory.
  // File names must be prefixed with a space ID followed by ':' to indicate that the file is to be copied to/from that space.
  // The method won't copy a directory into itself. It can copy the root directory from one space to another.
  copy(sources, target) {
    return this._rpc.call("file.copy", [sources, target]);
  }

  // Move moves files between spaces.
  // The method can move a single file to an existing file or directory, or multiple files to an existing directory.
  // File names must be prefixed with a space ID followed by ':' to indicate that the file is to be moved to/from that space.
  // The method won't move a directory into itself. It can move the root directory from one space to another.
  move(sources, target) {
    return this._rpc.call("file.move", [sources, target]);
  }

  // Deprecated. Use SpaceCollection's Get().
  getRevision(spaceId) {
    return this._rpc.call("file.getRevision", [spaceId]);
  }

  // Deprecated. Use FileAccessLogCollection's Query().
  getAccessLog(spaceId, pathname, beforeDate, afterDate, sort, limit) {
    return this._rpc.call("file.getAccessLog", [spaceId, pathname, beforeDate, afterDate, sort, limit]);
  }

  // Downloads a file.
  // Set `revision` < 1 for the latest.
  // The user must be part of the space.
  download(spaceId, path, revision, target) {
    return this._rpc.call("file.download", [spaceId, path, revision, this._rpc.proxy(target)]);
  }

  // Downloads a file or a directory to disk.
  // - Set `revision` < 1 for the latest.
  // - `path` is the source path in the space.
  // - `target` is the target path on disk; it may be a folder, it will be created if missing and overwritten if both the source and the target are files. The method returns an error if the source is a folder and the target a file.
  // The method returns the path to the downloaded file.
  // The user must be part of the space.
  downloadToDisk(spaceId, path, revision, target) {
    return this._rpc.call("file.downloadToDisk", [spaceId, path, revision, target]);
  }

  // DownloadZip downloads a list of files into a zip archive.
  // The files are not compressed to prevent performance issue in JavaScript.
  downloadZip(spaceId, sources, revision, target) {
    return this._rpc.call("file.downloadZip", [spaceId, sources, revision, this._rpc.proxy(target)]);
  }

  // GetSuggestedZipName returns the suggested name for a zip archive.
  // The method expects the same parameters as FileCollection's DownloadZip().
  getSuggestedZipName(spaceId, sources, revision) {
    return this._rpc.call("file.getSuggestedZipName", [spaceId, sources, revision]);
  }

  // GetExpectedZipSize returns the expected size in bytes of a zip archive.
  // The method expects the same parameters as FileCollection's DownloadZip().
  getExpectedZipSize(spaceId, sources, revision) {
    return this._rpc.call("file.getExpectedZipSize", [spaceId, sources, revision]);
  }

  // DownloadZipToDisk downloads a list of files into a zip archive on disk.
  // The files are not compressed to be consistent with FileCollection's DownloadZip().
  // The method returns the path of the archive.
  downloadZipToDisk(spaceId, sources, revision, target) {
    return this._rpc.call("file.downloadZipToDisk", [spaceId, sources, revision, target]);
  }

  // GetContentInfo returns the information to download and decrypt a file.
  // The method raises an error if the antivirus is enabled, to prevent the client to bypass it.
  getContentInfo(spaceId, revision, path) {
    return this._rpc.call("file.getContentInfo", [spaceId, revision, path]);
  }

  // Rename renames a file or a directory.
  // The user must have write rights for the space.
  rename(spaceId, pathname, newBasename) {
    return this._rpc.call("file.rename", [spaceId, pathname, newBasename]);
  }

  // Remove removes a file or a directory recursively.
  // The method can remove the root directory (ie. all the files in the space).
  // The user must have write rights for the space.
  remove(spaceId, pathname) {
    return this._rpc.call("file.remove", [spaceId, pathname]);
  }

  // Creates a random directory on disk.
  tempDir(dirname) {
    return this._rpc.call("file.tempDir", [dirname]);
  }

  // GetHistoryHeatmap returns the heatmap of the file changes history.
  getHistoryHeatmap(spaceId, trackingId, year, options) {
    return this._rpc.call("file.getHistoryHeatmap", [spaceId, trackingId, year, options]);
  }

  // GetHistory returns the file changes history.
  getHistory(spaceId, trackingId, limit, options) {
    return this._rpc.call("file.getHistory", [spaceId, trackingId, limit, options]);
  }

  // GetNumberDownloads returns the number of downloads of a file at a given version.
  getNumberDownloads(trackingId, fileVersion) {
    return this._rpc.call("file.getNumberDownloads", [trackingId, fileVersion]);
  }

  // GetDownloadList returns the list of downloads for a file at a given version.
  // Pass an empty `cursor` for the first call then use [DownloadList.NewCursor].
  getDownloadList(trackingId, fileVersion, downloadType, limit, cursor) {
    return this._rpc.call("file.getDownloadList", [trackingId, fileVersion, downloadType, limit, cursor]);
  }

  // DownloadHistory downloads the file history to a CSV file.
  // The parameter `target` may be:
  // - A path (string).
  // -- If the path does not exist, a file will be created at the location.
  // -- If the path exists and is a file, the file will be replaced.
  // -- If the path exists and is a folder, a file named "<filename.extension>_export-YYYY-MM-DD.csv" will be created in the folder. The file is replaced if it already exists.
  // - An OutputFile.
  // 
  // Texts are translated according to the current locale. See UserAgent's SetLocale.
  // 
  // The method returns an empty CSV if the server cannot find the tracking ID or does not support full file history export (< v4.32).
  downloadHistory(spaceId, trackingId, target) {
    if (typeof target == "string") {
      target = {"string": target}
    }
    if (target && typeof target.write == "function") {
      target = {"OutputFile": this._rpc.proxy(target)}
    }
    return this._rpc.call("file.downloadHistory", [spaceId, trackingId, target]);
  }

  // GetViewableTypes returns the list of supported file types for a viewer.
  // ViewableFileType's Label may change after a call to UserAgent's SetLocale(). Otherwise the list is hard-coded.
  getViewableTypes() {
    return this._rpc.call("file.getViewableTypes", []);
  }

}

// File downloads are logged by the server.
class FileAccessLogCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Query returns the access log.
  // `before` and `after` may be empty, `limit` is clamped to [0, 1000].
  query(spaceId, path, beforeDate, afterDate, sort, limit) {
    return this._rpc.call("fileAccessLog.query", [spaceId, path, beforeDate, afterDate, sort, limit]);
  }

}

// File cache configuration.
class FileCacheCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Get retrieves the cache configuration and usage.
  // The configuration is persisted to disk.
  get() {
    return this._rpc.call("fileCache.get", []);
  }

  // Watch registers an observer on the result of FileCacheCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(obs) {
    return this._rpc.call("fileCache.watch", [this._rpc.proxy(obs)]);
  }

  // SetPath sets the location of the cache directory.
  // The method creates the directory if missing.
  setPath(dirname) {
    return this._rpc.call("fileCache.setPath", [dirname]);
  }

  // SetSize sets the maximum cache size in bytes.
  // The method truncates the cache content to the new size.
  // A null `size` both clears and disables the cache.
  // NB. When a file is about to be cached and the maximum cache size is exceeded, a sufficient number of files are deleted on a least-recently-used basis.
  // See also FileCacheCollection's Remove().
  setSize(size) {
    return this._rpc.call("fileCache.setSize", [size]);
  }

  // Remove deletes the cache directory and resets the configuration.
  remove() {
    return this._rpc.call("fileCache.remove", []);
  }

}

// A space member may watch paths to files or directories and receive in-app notifications, and optionaly email notifications, upon downloads.
// See AccountSettingsCollection.
class FileDownloadSubscriptionCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Add subscribes a list of paths in a space.
  add(spaceId, pathnames) {
    return this._rpc.call("fileDownloadSubscription.add", [spaceId, pathnames]);
  }

  // Get returns the file subscriptions.
  get(spaceId) {
    return this._rpc.call("fileDownloadSubscription.get", [spaceId]);
  }

  // Watch registers an observer on the result of FileDownloadSubscriptionCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(spaceId, obs) {
    return this._rpc.call("fileDownloadSubscription.watch", [spaceId, this._rpc.proxy(obs)]);
  }

  // Remove unsubscribes a list of paths in a space.
  remove(spaceId, pathnames) {
    return this._rpc.call("fileDownloadSubscription.remove", [spaceId, pathnames]);
  }

}

// A file revision contains the whole file tree of a space at a given point in time.
// You may read the content of a file revision with FileCollection.
// Note that the server may remove older revisions.
class FileRevisionCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Query returns the list of file revisions in a space, filtered by date and number.
  // You may set an empty `notAfterDate`, a zero `notAfterNumber` or both to deactivate the parameter.
  // The number of results is limited by the server, typically around 1000 items.
  // Requires ServerCapabilityFileHistory.
  query(spaceId, notAfterDate, notAfterNumber, count, options) {
    return this._rpc.call("fileRevision.query", [spaceId, notAfterDate, notAfterNumber, count, options]);
  }

  // WatchQuery registers an observer on the result of FileRevisionCollection's Query().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchQuery(spaceId, notAfterDate, notAfterNumber, count, options, obs) {
    return this._rpc.call("fileRevision.watchQuery", [spaceId, notAfterDate, notAfterNumber, count, options, this._rpc.proxy(obs)]);
  }

  // Deprecated. Use FileRevisionCollection's Query().
  list(spaceId, notAfterDate, notAfterNumber, count, options) {
    return this._rpc.call("fileRevision.list", [spaceId, notAfterDate, notAfterNumber, count, options]);
  }

  // Deprecated. Use FileRevisionCollection's Query().
  getLatest(spaceId) {
    return this._rpc.call("fileRevision.getLatest", [spaceId]);
  }

  // Restore pushes a given file revision to the server as the new one.
  restore(spaceId, number) {
    return this._rpc.call("fileRevision.restore", [spaceId, number]);
  }

}

// A space member may create an URL link to a file to share its content with an unregistered user.
// The sharing is protected by a secret that has to be sent to the recipient.
class FileSharingCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // GetSuggestedName returns the suggested name for a sharing.
  getSuggestedName(spaceId, paths) {
    return this._rpc.call("fileSharing.getSuggestedName", [spaceId, paths]);
  }

  // Create creates a new sharing.
  // The resulting FileSharingRecord contains a valid URL.
  // Sharing multiple files at once requires ServerCapabilityFileSharingMultipleFiles. See ServerSettingsCollection's Get().
  create(spaceId, paths, options) {
    return this._rpc.call("fileSharing.create", [spaceId, paths, options]);
  }

  // List returns the file sharings.
  // According the user's role in the space, the server may return all the sharings or only the one belonging to the user.
  // FileSharingRecord's URL will be empty.
  list(spaceId) {
    return this._rpc.call("fileSharing.list", [spaceId]);
  }

  // GetKey extracts the key from a sharing URL (including the hint for long key).
  // The URL returned by Create() always contain a secret key while the URL sent by SendByEmail() never does.
  getKey(sharingUrl) {
    return this._rpc.call("fileSharing.getKey", [sharingUrl]);
  }

  // GetKeyHint extracts the key hint from a sharing URL.
  // Client should not use the hint for short code. See FileSharingCollection's GetInfo().
  getKeyHint(sharingUrl) {
    return this._rpc.call("fileSharing.getKeyHint", [sharingUrl]);
  }

  // GetUrlWithoutKey returns a sharing URL without a key.
  getUrlWithoutKey(sharingUrl) {
    return this._rpc.call("fileSharing.getUrlWithoutKey", [sharingUrl]);
  }

  // GetToken extracts the token from a sharing URL.
  getToken(sharingUrl) {
    return this._rpc.call("fileSharing.getToken", [sharingUrl]);
  }

  // GetInfo downloads the encrypted sharing information for a sharing URL.
  // Client should parse the result (JSON) and check for the presence of "short_code". If the field is true, the key hint is useless (it is almost the same size as the key itself).
  // See DecryptInfo.
  getInfo(sharingUrl) {
    return this._rpc.call("fileSharing.getInfo", [sharingUrl]);
  }

  // DecryptInfo decrypts the information returned by GetInfo.
  // The result contains only the file name and size.
  decryptInfo(serverUrl, encryptedInfo, key) {
    return this._rpc.call("fileSharing.decryptInfo", [serverUrl, encryptedInfo, key]);
  }

  // Download downloads a sharing.
  // If the sharing contains multiple files or an empty folder the resulting file is an uncompressed zip archive.
  download(sharingUrl, sharingKey, target) {
    return this._rpc.call("fileSharing.download", [sharingUrl, sharingKey, this._rpc.proxy(target)]);
  }

  // DownloadToDisk downloads a sharing and writes it to disk.
  // If the sharing contains multiple files or an empty folder the resulting file is an uncompressed zip archive.
  downloadToDisk(sharingUrl, sharingKey, target) {
    return this._rpc.call("fileSharing.downloadToDisk", [sharingUrl, sharingKey, target]);
  }

  // SendByEmail sends an email containing a sharing URL.
  // The method strips the secret key from the URL before requesting the server to send the email.
  sendByEmail(recipients, message, sharingUrl) {
    return this._rpc.call("fileSharing.sendByEmail", [recipients, message, sharingUrl]);
  }

  // Updates a file sharing.
  // The method returns ErrorCodeInvalidParameter if no option is defined.
  update(spaceId, token, options) {
    return this._rpc.call("fileSharing.update", [spaceId, token, options]);
  }

  // Deletes a file sharing.
  delete(spaceId, token) {
    return this._rpc.call("fileSharing.delete", [spaceId, token]);
  }

  // Upload uploads a file to the personal space and returns a sharing URL.
  // The method is intended for the Outlook Add-in and exposes the minimum API possible.
  upload(file) {
    return this._rpc.call("fileSharing.upload", [this._rpc.proxy(file)]);
  }

}

// A space member may watch paths to files or directories and receive in-app notifications, and optionaly email notifications, upon changes.
// See AccountSettingsCollection.
class FileSubscriptionCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Add subscribes a list of paths in a space.
  add(spaceId, pathnames) {
    return this._rpc.call("fileSubscription.add", [spaceId, pathnames]);
  }

  // Get returns the file subscriptions.
  get(spaceId) {
    return this._rpc.call("fileSubscription.get", [spaceId]);
  }

  // Watch registers an observer on the result of FileSubscriptionCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(spaceId, obs) {
    return this._rpc.call("fileSubscription.watch", [spaceId, this._rpc.proxy(obs)]);
  }

  // Remove unsubscribes a list of paths in a space.
  remove(spaceId, pathnames) {
    return this._rpc.call("fileSubscription.remove", [spaceId, pathnames]);
  }

}

// File upload creation, cancelation and retry.
class FileUploadCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Create starts uploading a file and returns the related record.
  // The client:
  // - May watch the record or use FileUploadCollection's Wait() to be notified of the completion.
  // - Should remove the record with FileUploadCollection's Delete() once completed.
  create(spaceId, path, source) {
    return this._rpc.call("fileUpload.create", [spaceId, path, this._rpc.proxy(source)]);
  }

  // List returns all the uploads.
  // See FileUploadCollection's Delete() to clean up the list.
  list() {
    return this._rpc.call("fileUpload.list", []);
  }

  // WatchAll registers an observer on the result of FileUploadCollection's List().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchAll(obs) {
    return this._rpc.call("fileUpload.watchAll", [this._rpc.proxy(obs)]);
  }

  // Get returns an upload given its ID.
  get(id) {
    return this._rpc.call("fileUpload.get", [id]);
  }

  // Watch registers an observer on the result of FileUploadCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(id, obs) {
    return this._rpc.call("fileUpload.watch", [id, this._rpc.proxy(obs)]);
  }

  // Wait blocks until the upload is done.
  // The method reports the progress and returns the transfer error if any.
  // Canceling this method does NOT cancel the transfer. See FileUploadCollection's Cancel().
  wait(id) {
    return this._rpc.call("fileUpload.wait", [id]);
  }

  // Cancel stops an upload.
  // The record is kept in the list and must be deleted manually with FileUploadCollection's Delete().
  // The client may resumes the upload with FileUploadCollection's Retry().
  cancel(id) {
    return this._rpc.call("fileUpload.cancel", [id]);
  }

  // Retry resumes an upload after a failure or a cancelation.
  // The method does nothing if the transfer is pending, inflight or done.
  retry(id) {
    return this._rpc.call("fileUpload.retry", [id]);
  }

  // Delete cancels and removes an upload from the list.
  delete(id) {
    return this._rpc.call("fileUpload.delete", [id]);
  }

}

// Connects to the instant messaging server.
// Once connected, the user may send messages with MessageCollection.
class MessagingModule {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Converts the timestamp of a message to an ISO 8601 date.
  getDateFromTimestamp(timestamp) {
    return this._rpc.call("im.getDateFromTimestamp", [timestamp]);
  }

  // Connect starts a new messaging session.
  // 
  // The method returns:
  // - ErrorCodeNotSupported if the server does not support messaging.
  // - ErrorCodeMessagingConnectionRefused if the connection fails.
  // 
  // The method does nothing if a session is already opened.
  connect() {
    return this._rpc.call("im.connect", []);
  }

  // IsConnected returns true if the messaging session is opened.
  // See also MessagingStatusCollection's Watch().
  isConnected() {
    return this._rpc.call("im.isConnected", []);
  }

  // Disconnect ends the messaging session, if any.
  disconnect() {
    return this._rpc.call("im.disconnect", []);
  }

  // Delete deletes a message from the conversation.
  // The user must be the author of the message.
  // Deprecated. Use MessageCollection's Delete().
  delete(spaceId, timestamp) {
    return this._rpc.call("im.delete", [spaceId, timestamp]);
  }

}

// A registered user may invite an unregistered user by email.
class InviteCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Create invites an external user.
  // On success, the server sends an email to the invitee with an URL containing the invite's token.
  create(email) {
    return this._rpc.call("invite.create", [email]);
  }

  // List returns the invites of the authenticated user.
  // Set `pageSize` to -2, `pageID` to -1, `orderBy` and `sort` to "" to use the server defaults.
  // Set `pageSize` to -1 to list all the invites.
  // Requires ServerCapabilityInvitesManagement. See ServerSettingsCollection's Get().
  // See InviteCollection's Count().
  list(pageSize, pageId, orderBy, sort) {
    return this._rpc.call("invite.list", [pageSize, pageId, orderBy, sort]);
  }

  // WatchAll registers an observer on the result of InviteCollection's List().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchAll(pageSize, pageId, orderBy, sort, obs) {
    return this._rpc.call("invite.watchAll", [pageSize, pageId, orderBy, sort, this._rpc.proxy(obs)]);
  }

  // Count returns the number of invites of the authenticated user.
  // The result is updated each time InviteCollection's List() is called.
  count() {
    return this._rpc.call("invite.count", []);
  }

  // Resend resends the invite email.
  // Requires ServerCapabilityInvitesManagement. See ServerSettingsCollection's Get().
  resend(id) {
    return this._rpc.call("invite.resend", [id]);
  }

  // Delete cancels an invite.
  // Requires ServerCapabilityInvitesManagement. See ServerSettingsCollection's Get().
  delete(id) {
    return this._rpc.call("invite.delete", [id]);
  }

  // GetDefaultRights retrieves the defaults rights of an invitee.
  // The default rights depend on the inviter. They may change when the user status does.
  getDefaultRights() {
    return this._rpc.call("invite.getDefaultRights", []);
  }

  // CreateWithRights invites an external user with given rights.
  createWithRights(email, canWrite, canCreateSpace, hasPersonalSpace, canInviteUsers, canSetInviteRights) {
    return this._rpc.call("invite.createWithRights", [email, canWrite, canCreateSpace, hasPersonalSpace, canInviteUsers, canSetInviteRights]);
  }

  // GetInfo retrieves an existing invite.
  // The client may not be authenticated to call this method.
  getInfo(serverUrl, token) {
    return this._rpc.call("invite.getInfo", [serverUrl, token]);
  }

  // Register registers an invited user.
  register(serverUrl, token, password, givenName, surname) {
    return this._rpc.call("invite.register", [serverUrl, token, password, givenName, surname]);
  }

  // GetLicenseUsage returns the license usage for the server of the authenticated user.
  // Requires ServerCapabilityInvitesManagement. See ServerSettingsCollection's Get().
  getLicenseUsage() {
    return this._rpc.call("invite.getLicenseUsage", []);
  }

  // GetUsersType returns the user type for each email in a list.
  // Requires ServerCapabilityInvitesManagement. See ServerSettingsCollection's Get().
  getUsersType(emails) {
    return this._rpc.call("invite.getUsersType", [emails]);
  }

  // RequestExtension requests the admin to extend the invite pool of the authenticated user.
  // Requires ServerCapabilityInviteExtensionRequest. See ServerSettingsCollection's Get().
  requestExtension(message, inviteCount) {
    return this._rpc.call("invite.requestExtension", [message, inviteCount]);
  }

}

// JSON-API service.
class JsonApiModule {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // FindAll returns all of the records for a given type.
  findAll(model, include) {
    return this._rpc.call("jsonApi.findAll", [model, include]);
  }

  // FindRecord returns the record with a given type and ID.
  findRecord(model, id, include) {
    return this._rpc.call("jsonApi.findRecord", [model, id, include]);
  }

  // Query returns the records that match a particular query.
  query(model, query, include) {
    return this._rpc.call("jsonApi.query", [model, query, include]);
  }

  // QueryRecord returns the record that matches a particular query.
  queryRecord(model, query, include) {
    return this._rpc.call("jsonApi.queryRecord", [model, query, include]);
  }

  // FindBelongsTo returns the record behind an asynchronous to-one relationship.
  findBelongsTo(model, id, rel) {
    return this._rpc.call("jsonApi.findBelongsTo", [model, id, rel]);
  }

  // FindHasMany returns the records behind an asynchronous to-many relationship.
  findHasMany(model, id, rel) {
    return this._rpc.call("jsonApi.findHasMany", [model, id, rel]);
  }

  // FindMany returns the records with a given type and IDs.
  // In most cases, the method has better network performance than calling JsonApiModule's FindRecord() for each ID.
  findMany(model, ids) {
    return this._rpc.call("jsonApi.findMany", [model, ids]);
  }

  // WatchFindAll registers an observer on the result of JsonApiModule's FindAll().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchFindAll(model, include, obs) {
    return this._rpc.call("jsonApi.watchFindAll", [model, include, this._rpc.proxy(obs)]);
  }

  // WatchFindRecord registers an observer on the result of JsonApiModule's FindRecord().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchFindRecord(model, id, include, obs) {
    return this._rpc.call("jsonApi.watchFindRecord", [model, id, include, this._rpc.proxy(obs)]);
  }

  // WatchQuery registers an observer on the result of JsonApiModule's Query().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchQuery(model, query, include, obs) {
    return this._rpc.call("jsonApi.watchQuery", [model, query, include, this._rpc.proxy(obs)]);
  }

  // WatchQueryRecord registers an observer on the result of JsonApiModule's QueryRecord().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchQueryRecord(model, query, include, obs) {
    return this._rpc.call("jsonApi.watchQueryRecord", [model, query, include, this._rpc.proxy(obs)]);
  }

}

// Logging configuration.
class LoggingModule {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // SetSystemLogEnabled toggles writing the logs to the system using:
  // - __android_log_write on Android,
  // - os_log on iOS,
  // - console.log on JavaScript,
  // - stderr otherwise.
  setSystemLogEnabled(enabled) {
    return this._rpc.call("logging.setSystemLogEnabled", [enabled]);
  }

  // IsSystemLogEnabled returns whether the logs are written to the system.
  // See LoggingModule's SetSystemLogEnabled().
  isSystemLogEnabled() {
    return this._rpc.call("logging.isSystemLogEnabled", []);
  }

  // StartLoggingToFile starts writing the logs to a file.
  // You can only have one log file at a time. This method closes the previous log file.
  // Logging to file stops when the size of the file exceeds the `limit` in bytes.
  // See LoggingModule's StopLoggingToFile().
  startLoggingToFile(path, limit) {
    return this._rpc.call("logging.startLoggingToFile", [path, limit]);
  }

  // StopLoggingToFile flushes the logs and closes the file.
  // See LoggingModule's StartLoggingToFile().
  stopLoggingToFile() {
    return this._rpc.call("logging.stopLoggingToFile", []);
  }

  // IsLoggingToFile returns whether the logs are written to a file.
  // See LoggingModule's StartLoggingToFile().
  isLoggingToFile() {
    return this._rpc.call("logging.isLoggingToFile", []);
  }

}

// Provides the progress information during UserAgent's Login().
class LoginProgressCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Returns the current login progress.
  get() {
    return this._rpc.call("loginProgress.get", []);
  }

  // Watch registers an observer on the result of LoginProgressCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(obs) {
    return this._rpc.call("loginProgress.watch", [this._rpc.proxy(obs)]);
  }

}

// Provides the login status. May be watched.
class LoginStatusCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Get returns the current login status.
  get() {
    return this._rpc.call("loginStatus.get", []);
  }

  // Watch registers an observer on the result of LoginStatusCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(obs) {
    return this._rpc.call("loginStatus.watch", [this._rpc.proxy(obs)]);
  }

}

// The user may share instant message with other members of a space, and attach files with AttachmentCollection.
// Note that the user agent must be connected to the messaging server using MessagingModule.
// See also SpaceCollection.
class MessageCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Create creates a pending message.
  // You may add attachments with AttachmentCollection's Add() or AttachmentCollection's AddFromDisk() then send the message with MessageCollection's Post().
  create(spaceId, text) {
    return this._rpc.call("message.create", [spaceId, text]);
  }

  // Post sends a pending or rejected message to the server, along with its attachments.
  // See MessageCollection's Create().
  post(id) {
    return this._rpc.call("message.post", [id]);
  }

  // Load loads `count` messages from the conversation history of `spaceID`.
  // The method always returns the newest messages in the history.
  // The message observers will receive the messages before this method returns.
  load(spaceId, count) {
    return this._rpc.call("message.load", [spaceId, count]);
  }

  // LoadMore loads `count` extra messages from the conversation history of `spaceID`.
  // The message observers will receive the messages before this method returns.
  loadMore(spaceId, count) {
    return this._rpc.call("message.loadMore", [spaceId, count]);
  }

  // HasMore returns whether more messages can be loaded from the server.
  hasMore(spaceId) {
    return this._rpc.call("message.hasMore", [spaceId]);
  }

  // Delete deletes a pending or delivered message.
  // The message is deleted server-side if possible (see MessageRecord's Deletable).
  // Otherwise it is hidden locally for the duration of the login session.
  delete(id) {
    return this._rpc.call("message.delete", [id]);
  }

  // List returns the messages in a conversation.
  // The result contains the messages received in real time, loaded with MessageCollection's Load() or created with MessageCollection's Create().
  list(spaceId) {
    return this._rpc.call("message.list", [spaceId]);
  }

  // WatchAll registers an observer on the result of MessageCollection's List().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchAll(spaceId, obs) {
    return this._rpc.call("message.watchAll", [spaceId, this._rpc.proxy(obs)]);
  }

  // Get returns an existing message.
  get(id) {
    return this._rpc.call("message.get", [id]);
  }

  // Watch registers an observer on the result of MessageCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(id, obs) {
    return this._rpc.call("message.watch", [id, this._rpc.proxy(obs)]);
  }

  // Inbox returns the last messages received on the web socket.
  // You should use MessageCollection's WatchInbox() to be notified in a timely fashion.
  inbox() {
    return this._rpc.call("message.inbox", []);
  }

  // WatchInbox registers an observer on the result of MessageCollection's Inbox().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchInbox(obs) {
    return this._rpc.call("message.watchInbox", [this._rpc.proxy(obs)]);
  }

}

// Reports status of the connection with the instant messaging server.
// See MessagingModule.
class MessagingStatusCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Get returns the current messaging status.
  get() {
    return this._rpc.call("messagingStatus.get", []);
  }

  // Watch registers an observer on the result of MessagingStatusCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(obs) {
    return this._rpc.call("messagingStatus.watch", [this._rpc.proxy(obs)]);
  }

}

class MFAModule {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Cancel cancels any multi-factor authentication in progress.
  // The method that has requested the MFA will fail with ErrorCodeCanceled.
  // The method does nothing if SecondFactorAuthenticator's OpenURL() or SecondFactorAuthenticator's RequestOTP() has not been called.
  cancel() {
    return this._rpc.call("mfa.cancel", []);
  }

}

// In-app notifications.
class NotificationCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // List returns all the notifications of the authenticated user.
  list() {
    return this._rpc.call("notification.list", []);
  }

  // WatchAll registers an observer on the result of NotificationCollection's List().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchAll(obs) {
    return this._rpc.call("notification.watchAll", [this._rpc.proxy(obs)]);
  }

  // Get returns a notification.
  get(id) {
    return this._rpc.call("notification.get", [id]);
  }

  // Watch registers an observer on the result of NotificationCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(id, obs) {
    return this._rpc.call("notification.watch", [id, this._rpc.proxy(obs)]);
  }

  // Query returns the notifications of the authenticated user in a space.
  query(spaceId) {
    return this._rpc.call("notification.query", [spaceId]);
  }

  // WatchQuery registers an observer on the result of NotificationCollection's Query().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchQuery(spaceId, obs) {
    return this._rpc.call("notification.watchQuery", [spaceId, this._rpc.proxy(obs)]);
  }

  // Updates the state of a notification.
  // The request is batched. See UserAgent.
  setState(id, state) {
    return this._rpc.call("notification.setState", [id, state]);
  }

  // Deletes a notification.
  // The request is batched. See UserAgent.
  delete(id) {
    return this._rpc.call("notification.delete", [id]);
  }

}

// Email OTP.
class OTPModule {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Set sends the one-time password to the server with all leading and trailing white space removed.
  // The method returns:
  // - ErrorCodeInvalidOTP when the code is wrong or expired and
  // - ErrorCodeTooManyOTPAttempts when the server denies the verification because of rate limiting.
  // Errors do not interrupt the authentication as there is no limit to the retry count.
  // If the user aborts the authentication, the application should either cancel the method that has requested the OTP in the first place or call OTPModule's Cancel(). This is important: there can only be one SPAKE authentication at any given time.
  // The method does nothing if SecondFactorAuthenticator's RequestOTP() has not been called.
  set(otp) {
    return this._rpc.call("otp.set", [otp]);
  }

  // Resend requests the server to send a new code.
  // The method does nothing if SecondFactorAuthenticator's RequestOTP() has not been called.
  resend() {
    return this._rpc.call("otp.resend", []);
  }

  // Cancel calls MFAModule's Cancel().
  cancel() {
    return this._rpc.call("otp.cancel", []);
  }

}

// Preadded space member collection for the authenticated user.
// 
// PreaddedSpaceMemberCollection's Create(), PreaddedSpaceMemberCollection's SetRole() and PreaddedSpaceMemberCollection's Delete() will batch some of their HTTP requests when called concurrently.
class PreaddedSpaceMemberCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Create invites an external user to a space.
  // The method overwrites existing request for the same user in the same space.
  // The method fails if the user is already registered.
  create(spaceId, email, role) {
    return this._rpc.call("preaddedSpaceMember.create", [spaceId, email, role]);
  }

  // List returns all the preadded space members of the authenticated user.
  // Use PreaddedSpaceMemberCollection's Query() to filter the list by space or by email; it will trigger less HTTP requests.
  // The method never returns records for a space unavailable to the device.
  list() {
    return this._rpc.call("preaddedSpaceMember.list", []);
  }

  // WatchAll registers an observer on the result of PreaddedSpaceMemberCollection's List().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchAll(obs) {
    return this._rpc.call("preaddedSpaceMember.watchAll", [this._rpc.proxy(obs)]);
  }

  // Get a preadded space member.
  // The method fails if the record exists but the space is not available to the device.
  get(id) {
    return this._rpc.call("preaddedSpaceMember.get", [id]);
  }

  // Watch registers an observer on the result of PreaddedSpaceMemberCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(id, obs) {
    return this._rpc.call("preaddedSpaceMember.watch", [id, this._rpc.proxy(obs)]);
  }

  // Query returns the preadded space members of the authenticated user filtered by space and/or email.
  // `spaceID` and `email` may be empty (either or both); if empty, the corresponding fields will not be filtered.
  // The method never returns records for a space unavailable to the device.
  query(spaceId, email) {
    return this._rpc.call("preaddedSpaceMember.query", [spaceId, email]);
  }

  // WatchQuery registers an observer on the result of PreaddedSpaceMemberCollection's Query().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchQuery(spaceId, email, obs) {
    return this._rpc.call("preaddedSpaceMember.watchQuery", [spaceId, email, this._rpc.proxy(obs)]);
  }

  // SetRole updates the role of a preadded space member.
  setRole(id, role) {
    return this._rpc.call("preaddedSpaceMember.setRole", [id, role]);
  }

  // Delete deletes a preadded space member.
  // Note that both SpaceMemberCollection's Add() and InviteCollection's Delete() removes the preadded space member automatically.
  delete(id) {
    return this._rpc.call("preaddedSpaceMember.delete", [id]);
  }

}

// The read cursors contains the read status of a conversation for the authenticated user.
// When the IM is connected the cursors are updated in real-time.
class ReadCursorCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // List returns the read cursors for all the conversations accessible to the authenticated user.
  // When offline, the method returns the result from the cache if available. See UserAgentStatusCollection.
  // The method returns a null unread count for deleted workspaces.
  list() {
    return this._rpc.call("readCursor.list", []);
  }

  // WatchAll registers an observer on the result of ReadCursorCollection's List().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchAll(obs) {
    return this._rpc.call("readCursor.watchAll", [this._rpc.proxy(obs)]);
  }

  // Get returns the read cursor of a space.
  // When offline, the method returns the result from the cache if available. See UserAgentStatusCollection.
  // The method returns a null unread count for deleted workspaces.
  get(spaceId) {
    return this._rpc.call("readCursor.get", [spaceId]);
  }

  // Watch registers an observer on the result of ReadCursorCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(spaceId, obs) {
    return this._rpc.call("readCursor.watch", [spaceId, this._rpc.proxy(obs)]);
  }

  // Advance moves the read cursor to the end of the conversation.
  advance(spaceId) {
    return this._rpc.call("readCursor.advance", [spaceId]);
  }

}

// A registered user may recover her account after a password loss, provided she has defined trustees with TrusteeCollection.
class RecoveryModule {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Claim initializes the password recovery procedure.
  // This will send an email to the claimant, containing a link to a page where he can choose his depositary to recover his password.
  claim(serverUrl, email) {
    return this._rpc.call("recovery.claim", [serverUrl, email]);
  }

  // GetOptions returns the options of the claimant for the recovery.
  getOptions(serverUrl, recoveryId) {
    return this._rpc.call("recovery.getOptions", [serverUrl, recoveryId]);
  }

  // Choose one of the password depositaries for the password recovery session.
  // This will send an email to the chosen depositary to require ask for recovery session.
  chooseDeposit(serverUrl, recoveryId, depositaryEmail) {
    return this._rpc.call("recovery.chooseDeposit", [serverUrl, recoveryId, depositaryEmail]);
  }

  // Called from the claimant to setup a new password.
  // See RecoveryModule's SetNewPassword().
  claimantDepositConnect(notifier, serverUrl, recoveryId, rdvUserId) {
    return this._rpc.call("recovery.claimantDepositConnect", [this._rpc.proxy(notifier), serverUrl, recoveryId, rdvUserId]);
  }

  // Called from the deposit to confirm the recovery (send recovery info).
  // See RecoveryModule's Confirm().
  depositClaimantConnect(recoveryId, rdvUserId) {
    return this._rpc.call("recovery.depositClaimantConnect", [recoveryId, rdvUserId]);
  }

  // Called from the deposit to confirm the SAS (short authentication string) received from a third paty media.
  // If the given SAS is matching the recovery data will be send to the claimant using the server rdv interface.
  // The method returns ErrorCodeRecoverySessionExpired after RecoveryModule's RetryCount() is exhausted.
  // The method returns ErrorCodeRecoverySessionDisconnected if called before RecoveryModule's DepositClaimantConnect() succeeded.
  confirm(recoveryId, sas) {
    return this._rpc.call("recovery.confirm", [recoveryId, sas]);
  }

  // RetryCount returns the number of tries remaining for the depositary to confirm the SAS.
  retryCount() {
    return this._rpc.call("recovery.retryCount", []);
  }

  // Called from the claimant to refil the new password.
  // The method returns ErrorCodeRecoverySessionDisconnected if called before RecoveryModule's ClaimantDepositConnect() succeeded.
  setNewPassword(serverUrl, rdvUserId, newPassword) {
    return this._rpc.call("recovery.setNewPassword", [serverUrl, rdvUserId, newPassword]);
  }

}

// Provides access to the security information.
class SecurityModule {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // GetSMCertificate returns the certificate of the security module on the server of the authenticated user.
  getSmCertificate() {
    return this._rpc.call("security.getSmCertificate", []);
  }

  // GetFingerprint returns the fingerprint of a certificate (or of its device tree if appropriate).
  // For user certificate, the method pins the results for the duration of the session so TrusteeCollection's Add() and SpaceMemberCollection's Add() are protected against man-in-the-middle attack.
  getFingerprint(certificate) {
    return this._rpc.call("security.getFingerprint", [certificate]);
  }

  // SetAV overrides the antivirus URL to use when the CSS requests AV verification.
  // If `url` is empty, the user agent never performs AV verification.
  // Default is to trust the server settings.
  setAv(url) {
    return this._rpc.call("security.setAv", [url]);
  }

  // SetDLP overrides the DLP URL to use when the CSS requests DLP verification.
  // If `url` is empty, the user agent never performs DLP verification.
  // Default is to trust the server settings.
  setDlp(url) {
    return this._rpc.call("security.setDlp", [url]);
  }

}

// An administrator may create security levels to restrict access to a space.
// The administrator creates the level, assigns the level to a device and the user assigns the level to a space.
// This feature is only available on desktop and mobile (as it requires a device).
class SecurityLevelCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Create creates a new security level.
  // The authenticated user must be an administrator.
  create(name, acronym, color, shape) {
    return this._rpc.call("securityLevel.create", [name, acronym, color, shape]);
  }

  // Deprecated. Use SecurityLevelCollection's Create().
  create_(name, acronym, color, shape) {
    return this._rpc.call("securityLevel.create_", [name, acronym, color, shape]);
  }

  // List returns the security levels on the server of the authenticated user.
  list() {
    return this._rpc.call("securityLevel.list", []);
  }

  // WatchAll registers an observer on the result of SecurityLevelCollection's List().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchAll(obs) {
    return this._rpc.call("securityLevel.watchAll", [this._rpc.proxy(obs)]);
  }

  // Deprecated. Use SecurityLevelCollection's List().
  list_(options) {
    return this._rpc.call("securityLevel.list_", [options]);
  }

  // Get returns a security level.
  get(id) {
    return this._rpc.call("securityLevel.get", [id]);
  }

  // Watch registers an observer on the result of SecurityLevelCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(id, obs) {
    return this._rpc.call("securityLevel.watch", [id, this._rpc.proxy(obs)]);
  }

  // Update updates an existing security level.
  // The authenticated user must be an administrator.
  update(id, name, acronym, color, shape) {
    return this._rpc.call("securityLevel.update", [id, name, acronym, color, shape]);
  }

  // Delete deletes an existing security level.
  // The authenticated user must be an administrator.
  delete(id) {
    return this._rpc.call("securityLevel.delete", [id]);
  }

}

// Retrieves the server settings for the user.
class ServerSettingsCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Get retrieves the settings of the server of the authenticated user.
  get() {
    return this._rpc.call("serverSettings.get", []);
  }

  // Watch registers an observer on the result of ServerSettingsCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(obs) {
    return this._rpc.call("serverSettings.watch", [this._rpc.proxy(obs)]);
  }

  // Query returns the settings of a given server.
  query(url) {
    return this._rpc.call("serverSettings.query", [url]);
  }

  // GetSupportEmail returns the support email of the connection server.
  // The support email is retrieved as soon as any of these methods is called:
  // - AccountCollection's Register().
  // - InviteCollection's Register().
  // - UserAgent's Login().
  // - DeviceCollection's Login().
  // It is reset by UserAgent's Logout().
  // See also ServerSettingsCollection's Query().
  getSupportEmail() {
    return this._rpc.call("serverSettings.getSupportEmail", []);
  }

  // Clear clears the settings' cache.
  clear() {
    return this._rpc.call("serverSettings.clear", []);
  }

}

// Provides the methods to export and restore the session of the authenticated user.
class SessionModule {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Exports the current session.
  save() {
    return this._rpc.call("session.save", []);
  }

  // Load the session.
  // The method synchronizes the data and restart the polling if the session is valid.
  load(session) {
    return this._rpc.call("session.load", [session]);
  }

  // Save the session to a temporary file, encrypted with the cache key.
  // It is permitted, but not safe, to write the session without a cache key.
  saveToDisk() {
    return this._rpc.call("session.saveToDisk", []);
  }

  // Load the session from a disk file, using the cache key to decrypt it.
  // The method calls SessionModule's Load() with the file contents.
  loadFromDisk(filename) {
    return this._rpc.call("session.loadFromDisk", [filename]);
  }

  // GetSigmaSession returns the SIGMA session of the authenticated user.
  getSigmaSession() {
    return this._rpc.call("session.getSigmaSession", []);
  }

  // WatchSigmaSession registers an observer on the result of SessionModule's GetSigmaSession().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchSigmaSession(obs) {
    return this._rpc.call("session.watchSigmaSession", [this._rpc.proxy(obs)]);
  }

}

// A space is a group of users who shares a set of cryptographic keys.
// 
// A space may contains files and instant messages. The space's kind hints at its usage, while not being technically restrictive:
// - In a workspace, users share files and instant messages.
// - A personal space is owned by one user and contains personal files.
// - A direct conversation allows two users to send instant messages to each others.
// - A group conversation allows two or more users to have a discussion on a topic.
// Note that conversations, while aimed at instant messaging, can (and do) contain files.
// 
// You may:
// - Add or remove space members with SpaceMemberCollection.
// - Read or write files with FileCollection.
// - Send and receive instant message with MessageCollection.
class SpaceCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // CreateWorkspace creates a workspace.
  // 
  // The authenticated user is automatically set as a space owner.
  // 
  // If ServerSettingsRecord's MinimalNumberOfOwnersPerWorkspace is defined and mandatory, you must ensure that the space has the proper number of owners or the method will return ErrorCodeForbidden.
  // NB. A workspace must always have at least one owner.
  createWorkspace(name, options) {
    return this._rpc.call("space.createWorkspace", [name, options]);
  }

  // CreateDirectConversation creates a one-to-one conversation.
  // If a direct conversation with the user and the same security levels already exists, the method returns it without creating a new one.
  // If `user` is the authenticated user, the method returns ErrorCodeInvalidParameter.
  // Note that a direct conversation is a space with SpaceUsageMessaging, an empty name and two users.
  // The security level IDs may be empty. Deleted and invalid levels are ignored.
  createDirectConversation(email, securityLevelIds) {
    return this._rpc.call("space.createDirectConversation", [email, securityLevelIds]);
  }

  // CreateGroupConversation creates a group conversation.
  // The name is mandatory. The method trims it before use.
  // Note that a group conversation is a space with SpaceUsageMessaging that is not a direct conversation.
  // The security level IDs may be empty. Deleted and invalid levels are ignored.
  createGroupConversation(name, emails, securityLevelIds) {
    return this._rpc.call("space.createGroupConversation", [name, emails, securityLevelIds]);
  }

  // List returns the spaces available to the authenticated user.
  // 
  // Since v4.36, space kinds are whitelisted:
  // - v4.36: workspace, personal space, direct conversation and group conversation.
  list() {
    return this._rpc.call("space.list", []);
  }

  // WatchAll registers an observer on the result of SpaceCollection's List().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchAll(obs) {
    return this._rpc.call("space.watchAll", [this._rpc.proxy(obs)]);
  }

  // Get returns a space.
  // The method requests the server if the space is not in cache.
  get(id) {
    return this._rpc.call("space.get", [id]);
  }

  // Watch registers an observer on the result of SpaceCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(id, obs) {
    return this._rpc.call("space.watch", [id, this._rpc.proxy(obs)]);
  }

  // Deprecated. Use SpaceCollection's Get().
  getInfo(spaceId) {
    return this._rpc.call("space.getInfo", [spaceId]);
  }

  // Deprecated. Use SpaceCollection's Get().
  getRevision(spaceId) {
    return this._rpc.call("space.getRevision", [spaceId]);
  }

  // Renames a space.
  // The user must be a owner of the space and the name valid.
  // The request is batched. See UserAgent.
  setName(id, name) {
    return this._rpc.call("space.setName", [id, name]);
  }

  // Updates the space description.
  // The user must be a owner of the space.
  // The request is batched. See UserAgent.
  setDescription(id, description) {
    return this._rpc.call("space.setDescription", [id, description]);
  }

  // Updates the space tags.
  // The user must be a owner of the space.
  // The request is batched. See UserAgent.
  setTags(id, tags) {
    return this._rpc.call("space.setTags", [id, tags]);
  }

  // Updates the space status.
  // The user must be a owner of the space.
  // The request is batched. See UserAgent.
  setStatus(id, status) {
    return this._rpc.call("space.setStatus", [id, status]);
  }

  // Updates the security levels.
  // The user must be a owner of the space and all the levels must be assignable.
  // The reason is required only if one ore more security levels are removed.
  // The request is batched. See UserAgent.
  setSecurityLevels(id, securityLevelIds, reason) {
    return this._rpc.call("space.setSecurityLevels", [id, securityLevelIds, reason]);
  }

  // Updates the subscription to the email notifications for the space conversation.
  // The user must be a owner of the space.
  // The request is batched. See UserAgent.
  setMessagingEmailNotification(id, enabled) {
    return this._rpc.call("space.setMessagingEmailNotification", [id, enabled]);
  }

  // CreateThumbnail transforms the provided image to a thumbnail image, as specified in SpaceCollection's SetThumbnail().
  // The method exists for preview purposes only and does not upload the image to the server.
  // The client may or may not use this image in SpaceCollection's SetThumbnail().
  createThumbnail(data) {
    return this._rpc.call("space.createThumbnail", [data]);
  }

  // SetThumbnail updates the thumbnail image of the space.
  // The method creates a thumbnail image of 256x256 pixels and fills it with the scaled image. To achieve the correct aspect ratio without stretching, the source image will be centered and cropped.
  // The result is encoded to PNG if it contains transparency and JPEG if it is fully opaque.
  // The source image must be GIF, JPEG, PNG, BMP, TIFF or WebP.
  // Requires ServerCapabilityThumbnail. See ServerSettingsCollection's Get().
  // The user must be a owner of the space.
  setThumbnail(id, data) {
    return this._rpc.call("space.setThumbnail", [id, data]);
  }

  // ClearThumbnail remove the thumbnail image of the space.
  // The user must be a owner of the space.
  clearThumbnail(id) {
    return this._rpc.call("space.clearThumbnail", [id]);
  }

  // GetThumbnail downloads the thumbnail image of the space.
  // The method return ErrorCodeNoThumbnail if the space has no thumbnail. See SpaceRecord's ThumbnailHash.
  // The image is cached in memory and stored to disk where applicable (using the chunk cache).
  // The format is 256x256 pixels, PNG or JPEG.
  getThumbnail(id) {
    return this._rpc.call("space.getThumbnail", [id]);
  }

  // GetDeletionDates returns the deletion dates for the deleted workspaces.
  // The method returns an empty list for servers without ServerCapabilityRetentionDelay.
  getDeletionDates() {
    return this._rpc.call("space.getDeletionDates", []);
  }

  // SetAllowCollaboration updates the authorization for online edition.
  // The authenticated user must be a owner of the space.
  setAllowCollaboration(spaceId, value) {
    return this._rpc.call("space.setAllowCollaboration", [spaceId, value]);
  }

  // GetPermissions returns the permissions of the authenticated user in a space.
  getPermissions(spaceId) {
    return this._rpc.call("space.getPermissions", [spaceId]);
  }

  // WatchPermissions registers an observer on the result of SpaceCollection's GetPermissions().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchPermissions(spaceId, obs) {
    return this._rpc.call("space.watchPermissions", [spaceId, this._rpc.proxy(obs)]);
  }

}

// The role of a space member defines her rights:
// - A "owner" can manage the member list, change the space metadata (name, description etc.) and delete the space.
// - A "member" can read and write files.
// - An "auditor" may only read files.
class SpaceMemberCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Add adds a new member to a space.
  // If the user is already in the space, her role is updated.
  // The member's fingerprint is automatically checked if SecurityModule's GetFingerprint() has been called for the user.
  // On success the method removes the matching invite in PreaddedSpaceMemberCollection.
  // If ServerSettingsRecord's MinimalNumberOfOwnersPerWorkspace is defined and mandatory, you must ensure that the space has the proper number of owners or the method will return ErrorCodeForbidden.
  // The request is batched. See UserAgent.
  add(spaceId, email, role) {
    return this._rpc.call("spaceMember.add", [spaceId, email, role]);
  }

  // AddMany adds multiple members to a space at once.
  // `users` is a map of email to role.
  // Each member's fingerprint are automatically checked if SecurityModule's GetFingerprint() has been called for the user.
  // If ServerSettingsRecord's MinimalNumberOfOwnersPerWorkspace is defined and mandatory, you must ensure that the space has the proper number of owners or the method will return ErrorCodeForbidden.
  // The request is batched. See UserAgent.
  addMany(spaceId, users) {
    return this._rpc.call("spaceMember.addMany", [spaceId, users]);
  }

  // List returns the members of a space.
  list(spaceId) {
    return this._rpc.call("spaceMember.list", [spaceId]);
  }

  // WatchAll registers an observer on the result of SpaceMemberCollection's List().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchAll(spaceId, obs) {
    return this._rpc.call("spaceMember.watchAll", [spaceId, this._rpc.proxy(obs)]);
  }

  // Get returns a member by its ID or email.
  get(spaceId, emailOrId) {
    return this._rpc.call("spaceMember.get", [spaceId, emailOrId]);
  }

  // Watch registers an observer on the result of SpaceMemberCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(spaceId, emailOrId, obs) {
    return this._rpc.call("spaceMember.watch", [spaceId, emailOrId, this._rpc.proxy(obs)]);
  }

  // Deprecated. Use SpaceMemberCollection's List().
  list_(spaceId) {
    return this._rpc.call("spaceMember.list_", [spaceId]);
  }

  // SetRole updates the role of a space member.
  // `spaceMemberID` may be the member's ID or email.
  // If ServerSettingsRecord's MinimalNumberOfOwnersPerWorkspace is defined and mandatory, you must ensure that the space has the proper number of owners or the method will return ErrorCodeForbidden.
  setRole(spaceId, spaceMemberId, role) {
    return this._rpc.call("spaceMember.setRole", [spaceId, spaceMemberId, role]);
  }

  // Deprecated. Use SpaceMemberCollection's SetRole().
  setRoleById(spaceId, userId, role) {
    return this._rpc.call("spaceMember.setRoleById", [spaceId, userId, role]);
  }

  // Remove removes a space member.
  // `spaceMemberID` may be the member's ID or email.
  // If ServerSettingsRecord's MinimalNumberOfOwnersPerWorkspace is defined and mandatory, you must ensure that the space has the proper number of owners or the method will return ErrorCodeForbidden.
  remove(spaceId, spaceMemberId) {
    return this._rpc.call("spaceMember.remove", [spaceId, spaceMemberId]);
  }

  // Deprecated. Use SpaceMemberCollection's Remove().
  removeById(spaceId, userId) {
    return this._rpc.call("spaceMember.removeById", [spaceId, userId]);
  }

}

// On desktop, the user agent may synchronize the files of the user to disk.
// Synchronization is bidirectional. In particular, files and folders deleted locally will be removed on the server.
// Use SyncSettingsCollection to configure the syncing and SyncStateCollection to retrieve status information.
class SyncModule {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Synchronize synchronizes the files of a space.
  synchronize(spaceId) {
    return this._rpc.call("sync.synchronize", [spaceId]);
  }

  // SynchronizeAll synchronizes all the space.
  synchronizeAll() {
    return this._rpc.call("sync.synchronizeAll", []);
  }

  // Touch marks a space for synchronization if matching the settings.
  // See SyncSettingsCollection.
  touch(spaceId) {
    return this._rpc.call("sync.touch", [spaceId]);
  }

  // TouchAll marks all the spaces matching the settings for synchronization.
  // See SyncSettingsCollection.
  touchAll() {
    return this._rpc.call("sync.touchAll", []);
  }

  // IsFileSynchronized returns whether the relative path exists on the server
  // for the provided space.
  isFileSynchronized(spaceId, pathname) {
    return this._rpc.call("sync.isFileSynchronized", [spaceId, pathname]);
  }

  // GetCryptoboxPath returns the root folder where all the space folders are created.
  // Empty if the spaces have never been synchronized.
  getCryptoboxPath() {
    return this._rpc.call("sync.getCryptoboxPath", []);
  }

}

// Defines the spaces to synchronize with SyncModule.
class SyncSettingsCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Get returns the settings for the authenticated user.
  // The default is SyncModeOff.
  get() {
    return this._rpc.call("syncSettings.get", []);
  }

  // Watch registers an observer on the result of SyncSettingsCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(obs) {
    return this._rpc.call("syncSettings.watch", [this._rpc.proxy(obs)]);
  }

  // Set writes to disk and applies the settings for the authenticated user.
  // Unavailable spaces are filtered out.
  set(settings) {
    return this._rpc.call("syncSettings.set", [settings]);
  }

}

// Retrieves the sync status and path of each space synchronized with SyncModule.
class SyncStateCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // List returns the state of the synchronized spaces.
  list() {
    return this._rpc.call("syncState.list", []);
  }

  // WatchAll registers an observer on the result of SyncStateCollection's List().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchAll(obs) {
    return this._rpc.call("syncState.watchAll", [this._rpc.proxy(obs)]);
  }

  // Get returns the state of a synchronized space.
  get(spaceId) {
    return this._rpc.call("syncState.get", [spaceId]);
  }

  // Watch registers an observer on the result of SyncStateCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(spaceId, obs) {
    return this._rpc.call("syncState.watch", [spaceId, this._rpc.proxy(obs)]);
  }

}

// Once registered, a user should add trustees to help her recover her account with RecoveryModule in case of a password loss.
class TrusteeCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Add adds a trustee to the authenticated user's account.
  // - `email` is the email of the trustee. The trustee's fingerprint is automatically checked if SecurityModule's GetFingerprint() has been called for the user.
  // - `password` is the password of the authenticated user. It is required from a device (ie. if the user has logged in with DeviceCollection's Login()) and ignored otherwise.
  // The server may request an extra authentication; see UserAgent's SetSecondFactorAuthenticator().
  add(email, password, enforced) {
    return this._rpc.call("trustee.add", [email, password, enforced]);
  }

  // List returns the trustees of the authenticated user.
  list() {
    return this._rpc.call("trustee.list", []);
  }

  // WatchAll registers an observer on the result of TrusteeCollection's List().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watchAll(obs) {
    return this._rpc.call("trustee.watchAll", [this._rpc.proxy(obs)]);
  }

  // Remove removes a trustee from the authenticated user's account.
  // The parameter `password` is required from a device (ie. if the user has logged in with DeviceCollection's Login()) and ignored otherwise.
  // Requires ServerCapabilityTrusteeDeletion. See ServerSettingsCollection's Get().
  // The server may request an extra authentication; see UserAgent's SetSecondFactorAuthenticator().
  remove(email, password) {
    return this._rpc.call("trustee.remove", [email, password]);
  }

  // GetStatus returns whether the client should add, remove or replace the enforced and/or invitee trustee.
  // See also TrusteeCollection's ResolveStatus().
  getStatus() {
    return this._rpc.call("trustee.getStatus", []);
  }

  // ResolveStatus applies the required changes regarding the enforced/invitee trustee.
  // The method does nothing if TrusteeCollection's GetStatus() is OK.
  // The parameter `password` is only required from a device.
  // The server may request an extra authentication; see UserAgent's SetSecondFactorAuthenticator().
  resolveStatus(password) {
    return this._rpc.call("trustee.resolveStatus", [password]);
  }

}

// Retrieves the list of users registered on the server.
// Note that the administrator may define the visibility of the users to each others.
class UserCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // List returns the users registered on the server of the authenticated user, but the authenticated user.
  // See UserCollection's Get() to retrieve more information on a user.
  list() {
    return this._rpc.call("user.list", []);
  }

  // Get returns the user registered on the server with the given email.
  // The method returns ErrorCodeNotFound if the user does not exist.
  // See UserCollection's Match() to process an address list.
  get(email) {
    return this._rpc.call("user.get", [email]);
  }

  // Parse parses an email address list (RFC 5322).
  // See UserCollection's Match().
  parse(emails) {
    return this._rpc.call("user.parse", [emails]);
  }

  // Match parses and matches an email address list (RFC 5322) with the users registered on the server.
  // On success the method returns an ExtendedUserRecord for each email in the list.
  // See ExtendedUserRecord's IsRegistered.
  match(emails) {
    return this._rpc.call("user.match", [emails]);
  }

  // Deprecated. Use UserCollection's List().
  list_() {
    return this._rpc.call("user.list_", []);
  }

  // Deprecated. Use UserCollection's Get().
  find(email) {
    return this._rpc.call("user.find", [email]);
  }

  // Deprecated. Use UserCollection's Match().
  findMany(emails) {
    return this._rpc.call("user.findMany", [emails]);
  }

}

// Provides access to all of the user agent features, by modules.
// 
// When possible, updates are batched. Compatible methods:
// - Delay their executions (15ms by default) to wait for further candidates.
// - Return a shared error, i.e. batched invocations fail or succeed together.
// Cancelling a batched method cancels all the methods in the batch.
// 
// The following collections support batching:
// - Spaces and files updates are batched by space.
// - Notifications updates and read cursors retrieval are batched without restriction.
// 
// See UserAgent's SetBatchingDelay().
class UserAgent {
  constructor(transport, settings) {
    let rpc = new RpcClient(transport);
    rpc.onready = async () => {
      settings = typeof settings === 'function' ? await settings() : settings;
      settings.cacheKeyVault = rpc.proxy(settings.cacheKeyVault)
      settings.localStorage = rpc.proxy(settings.localStorage)
      if (!settings.hasOwnProperty("localStorage")) {
        settings.localStorage = rpc.proxy(new LocalStorage());
      }
      let onready = settings.onready;
      delete settings.onready;
      let cacheKey = await rpc.call("initialize", [settings, null]);
      if (onready) await onready(cacheKey);
    };
    rpc.onerror = (error) => {
      this.errHandler && this.errHandler(error);
    }
    this.account = new AccountCollection(rpc);
    this.accountSettings = new AccountSettingsCollection(rpc);
    this.attachment = new AttachmentCollection(rpc);
    this.breadcrumb = new BreadcrumbCollection(rpc);
    this.cache = new CacheModule(rpc);
    this.category = new CategoryCollection(rpc);
    this.crypto = new CryptoModule(rpc);
    this.depositBox = new DepositBoxModule(rpc);
    this.depositBoxForm = new DepositBoxFormModule(rpc);
    this.device = new DeviceCollection(rpc);
    this.event = new EventCollection(rpc);
    this.file = new FileCollection(rpc);
    this.fileAccessLog = new FileAccessLogCollection(rpc);
    this.fileCache = new FileCacheCollection(rpc);
    this.fileDownloadSubscription = new FileDownloadSubscriptionCollection(rpc);
    this.fileRevision = new FileRevisionCollection(rpc);
    this.fileSharing = new FileSharingCollection(rpc);
    this.fileSubscription = new FileSubscriptionCollection(rpc);
    this.fileUpload = new FileUploadCollection(rpc);
    this.im = new MessagingModule(rpc);
    this.invite = new InviteCollection(rpc);
    this.jsonApi = new JsonApiModule(rpc);
    this.logging = new LoggingModule(rpc);
    this.loginProgress = new LoginProgressCollection(rpc);
    this.loginStatus = new LoginStatusCollection(rpc);
    this.message = new MessageCollection(rpc);
    this.messagingStatus = new MessagingStatusCollection(rpc);
    this.mfa = new MFAModule(rpc);
    this.notification = new NotificationCollection(rpc);
    this.otp = new OTPModule(rpc);
    this.preaddedSpaceMember = new PreaddedSpaceMemberCollection(rpc);
    this.readCursor = new ReadCursorCollection(rpc);
    this.recovery = new RecoveryModule(rpc);
    this.security = new SecurityModule(rpc);
    this.securityLevel = new SecurityLevelCollection(rpc);
    this.serverSettings = new ServerSettingsCollection(rpc);
    this.session = new SessionModule(rpc);
    this.space = new SpaceCollection(rpc);
    this.spaceMember = new SpaceMemberCollection(rpc);
    this.sync = new SyncModule(rpc);
    this.syncSettings = new SyncSettingsCollection(rpc);
    this.syncState = new SyncStateCollection(rpc);
    this.trustee = new TrusteeCollection(rpc);
    this.user = new UserCollection(rpc);
    this.userAgentStatus = new UserAgentStatusCollection(rpc);
    this.userStatus = new UserStatusCollection(rpc);
    this.validation = new ValidationModule(rpc);
    this._rpc = rpc;
    this._rpc.connect();
  }

  setGlobalErrorHandler(handler) {
    this.errHandler = handler;
  }

  // encodeUint8Array converts a Uint8Array to a type suitable to the underlying RPC transport.
  encodeUint8Array(value) {
    return this._rpc._transport.encodeUint8Array(value);
  }

  // decodeUint8Array converts binary data from the RPC transport to a Uint8Array.
  decodeUint8Array(value) {
    return this._rpc._transport.decodeUint8Array(value);
  }

  // NewCacheKey returns a new random key suitable to encrypt the cache.
  // See UserAgent's Initialize().
  newCacheKey() {
    return this._rpc.call("newCacheKey", []);
  }

  // Login connects a user to a server.
  login(serverUrl, email, password) {
    return this._rpc.call("login", [serverUrl, email, password]);
  }

  // IsLoggedIn returns true if the user is authenticated.
  // See also LoginStatusCollection's Watch().
  isLoggedIn() {
    return this._rpc.call("isLoggedIn", []);
  }

  // Logout disconnects the user from the server.
  // The method requests the server to close the SPAKE and SIGMA sessions then waits for the background processing of the low-level notifications to terminate, clears the local database then dispatch the logout event to the client.
  logout() {
    return this._rpc.call("logout", []);
  }

  // Deprecated. Use UserCollection's Parse().
  parseBulkEmail(bulk) {
    return this._rpc.call("parseBulkEmail", [bulk]);
  }

  // Deprecated. Use UserCollection's Match().
  searchUserWithBulk(emailBulk) {
    return this._rpc.call("searchUserWithBulk", [emailBulk]);
  }

  // Deprecated. Use UserCollection's Get().
  searchUser(email) {
    return this._rpc.call("searchUser", [email]);
  }

  // Deprecated. Use UserAgentStatusCollection's Get().
  hasLocalChanges() {
    return this._rpc.call("hasLocalChanges", []);
  }

  // Sets the language for the translated strings, namely errors and UI notifications.
  // The default `lang` is "en". The supported `lang` are "en" and "fr".
  // The method matches `lang` with the closest supported locale and returns its choice.
  // NB. all the UserAgent instances share the same locale.
  setLocale(lang) {
    return this._rpc.call("setLocale", [lang]);
  }

  // SetSecondFactorAuthenticator registers the callbacks for an optional extra authentication.
  setSecondFactorAuthenticator(auth) {
    return this._rpc.call("setSecondFactorAuthenticator", [this._rpc.proxy(auth)]);
  }

  // SetBatchingDelay updates the waiting time for batched methods.
  // `delay` is in milliseconds, it is 15ms by default.
  // See UserAgent.
  setBatchingDelay(milliseconds) {
    return this._rpc.call("setBatchingDelay", [milliseconds]);
  }

  // StopWatching unregisters a live query.
  stopWatching(queryId) {
    return this._rpc.call("stopWatching", [queryId]);
  }

  // CheckConnectivity forces the user agent to verify the reachability of the Cryptobox server.
  // The user agent maintains an online/offline status that helps determine how the cache should be used.
  // When offline, it sends an HTTP request regularly until the server responds (up to every 15 min).
  // Applications that have finer-grained information should call this method whenever they see fit (by eg. when the network connectivity changes or when the application enters the foreground).
  checkConnectivity() {
    return this._rpc.call("checkConnectivity", []);
  }

}

// User agent status collection.
class UserAgentStatusCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Get return the user agent status.
  get() {
    return this._rpc.call("userAgentStatus.get", []);
  }

  // Watch registers an observer on the result of UserAgentStatusCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(obs) {
    return this._rpc.call("userAgentStatus.watch", [this._rpc.proxy(obs)]);
  }

}

// The administrator defines the access rights of each user. The authenticated user can retrieve her own rights using this module.
class UserStatusCollection {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // Get returns the status of the authenticated user.
  get() {
    return this._rpc.call("userStatus.get", []);
  }

  // Watch registers an observer on the result of UserStatusCollection's Get().
  // You may stop observing the query by calling UserAgent's StopWatching().
  watch(obs) {
    return this._rpc.call("userStatus.watch", [this._rpc.proxy(obs)]);
  }

}

// Provides the methods to validate the user inputs before they are sent to the user agent.
class ValidationModule {
  constructor(rpc) {
    this._rpc = rpc;
  }

  // GetPasswordScore returns the password score according to the server settings.
  // The method returns ErrorCodePasswordTooLong if the password is longer than 128 characters.
  getPasswordScore(serverUrl, email, password, givenName, surname) {
    return this._rpc.call("validation.getPasswordScore", [serverUrl, email, password, givenName, surname]);
  }

  // Deprecated: Use ValidationModule's GetPasswordScore() instead.
  getPasswordStrength(serverUrl, email, password, givenName, surname) {
    return this._rpc.call("validation.getPasswordStrength", [serverUrl, email, password, givenName, surname]);
  }

  // CheckPassword validates a password.
  // On failure, the method typically returns ErrorCodePasswordTooShort, ErrorCodePasswordTooWeak or ErrorCodePasswordTooLong.
  checkPassword(serverUrl, email, password, givenName, surname) {
    return this._rpc.call("validation.checkPassword", [serverUrl, email, password, givenName, surname]);
  }

  // IsPasswordValid returns whether a password is valid.
  // The method calls ValidationModule's CheckPassword().
  isPasswordValid(serverUrl, email, password, givenName, surname) {
    return this._rpc.call("validation.isPasswordValid", [serverUrl, email, password, givenName, surname]);
  }

  // NewZxcvbnUserInputs improves the user inputs to inject into zxcvbn score computation.
  // Deprecated. See ValidationModule's GetPasswordStrength().
  newZxcvbnUserInputs(serverUrl, email, password, givenName, surname) {
    return this._rpc.call("validation.newZxcvbnUserInputs", [serverUrl, email, password, givenName, surname]);
  }

  // ConvertZxcvbnScoreToPasswordStrength converts a zxcvbn score to a password strength, with regard to the server configuration.
  // Deprecated. See ValidationModule's GetPasswordStrength().
  convertZxcvbnScoreToPasswordStrength(serverUrl, score) {
    return this._rpc.call("validation.convertZxcvbnScoreToPasswordStrength", [serverUrl, score]);
  }

  // IsNameValid assesses the validity of a name for a space or a file.
  isNameValid(name) {
    return this._rpc.call("validation.isNameValid", [name]);
  }

  // IsNameSafeOnWindows assesses the validity of a filename for Windows.
  isNameSafeOnWindows(name) {
    return this._rpc.call("validation.isNameSafeOnWindows", [name]);
  }

  // CheckSpaceCreationOrRenaming verifies a name is suitable to create a space.
  checkSpaceCreationOrRenaming(name) {
    return this._rpc.call("validation.checkSpaceCreationOrRenaming", [name]);
  }

  // CheckFileCreation verifies a name is suitable to create a file or a folder in a space.
  checkFileCreation(spaceId, dirname, basename, isDirectory) {
    return this._rpc.call("validation.checkFileCreation", [spaceId, dirname, basename, isDirectory]);
  }

  // CheckFileRenaming verifies a name is suitable to rename a file or a folder in a space.
  checkFileRenaming(spaceId, dirname, oldName, newName) {
    return this._rpc.call("validation.checkFileRenaming", [spaceId, dirname, oldName, newName]);
  }

  // Limits returns the limits enforced by the user agent.
  limits() {
    return this._rpc.call("validation.limits", []);
  }

}

export default UserAgent;

