// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo } from '@ember-data/model';

// The status of the authenticated user account.
// See UserStatusCollection.
export default class extends Model {
  // Whether the user is an administrator.
  // @type boolean
  @attr isAdmin;
  
  // Account state (active, blocked, etc.).
  // @type UserState
  @attr state;
  
  // Whether the user can upload files.
  // @type boolean
  @attr canWrite;
  
  // Whether the user can create spaces.
  // @type boolean
  @attr canCreateSpace;
  
  // Whether the user has a personal space.
  // @type boolean
  @attr hasPersonalSpace;
  
  // Whether the user can invite others.
  // @type boolean
  @attr canInviteUsers;
  
  // Whether the user can set the rights along the invitation.
  // @type boolean
  @attr canSetInviteRights;
  
  // Whether the user can use online collaboration.
  // @type boolean
  @attr canUseOnlineCollaboration;
  
  // The license pool of the user.
  // @type LicensePool
  @attr licensePool;
  
  // User first name.
  // @type string
  @attr givenName;
  
  // User last name.
  // @type string
  @attr surname;
  
  // Email of the enforced trustee. May be empty (no enforced trustee).
  // @type string
  @attr enforcedTrustee;
  
  @belongsTo('user', { async: false, inverse: null }) inviter;
}

