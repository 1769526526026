import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { tBoxClient } from 'client/initializers/init-toolbox';
import BlobFileWriter from 'client/toolbox/blob-file-writer';
import FileReader from 'client/toolbox/file-reader';

// Defines how long to wait for file changes after the editor is closed.
const GRACE_PERIOD = 60_000; // 1 min

// Unique message identifier for the web session.
var nextID = 1;

export default class Thinkfree extends Service {
  @service account;
  @service intl;
  @service notify;

  // Supported file extensions
  fileExt = [
    '.cell',
    '.doc',
    '.docx',
    '.odp',
    '.ods',
    '.odt',
    '.ppt',
    '.pptx',
    '.rtf',
    '.show',
    '.xls',
    '.xlsx',
  ];

  @tracked isEditorOpened = false;
  @tracked numberOfFilesBeingSaved = 0;

  isCollaborationEnabled(space, file) {
    return (  
      this.account.serverSettings.enableOnlineCollaboration && 
      this.account.status.canUseOnlineCollaboration &&
      space.allowCollaboration &&
      this.account.status.canWrite &&
      space.status === 'active' &&
      !space.isReader &&
      !space.isViewer &&
      !file.isFolder
    );
  }

  canOpenFileEditor(space, file) {
    return (
      this.isCollaborationEnabled(space, file) &&
      this.fileExt.includes(file.ext)
    );
  }

  async open(file) {
    if (!file.trackingId) {
      throw this.trackingError();
    }
    const { collaborationAdapterUrl } = this.account.serverSettings;
    const writer = new BlobFileWriter();
    await tBoxClient.file.download(file.spaceId, file.path, -1, writer);
    const blob = new Blob(writer.chunkBlobs);
    const form = new FormData();
    form.set('space_id', file.spaceId);
    form.set('tracking_id', file.trackingId);
    form.set('file', blob, file.path);
    form.set('modified', file.lastModified);
    form.set('locale', navigator.language);
    const url = `${collaborationAdapterUrl}/session`;
    const resp = await this.fetch(url, {
      method: 'POST',
      headers: await this.authorizationHeaders(),
      body: form,
      credentials: 'include',
    });
    const { editor_app, editor_url, editor_origin, session_url, session_token, logout_url } = await resp.json().catch((e) => {
      throw this.httpResponseReadingError('POST', fileUrl, resp, e)
    })
    const fileUrl = session_url + '/file';
    const headers = {
      'Authorization': 'Bearer ' + session_token,
    };

    const editor = new ThinkfreeEditor(editor_app, editor_origin);
    await editor.connect();

    const onunload = () => navigator.sendBeacon(logout_url);
    window.addEventListener('unload', onunload);

    const ctrl = new AbortController();
    const cleanup = () => {
      window.removeEventListener('unload', onunload);
      ctrl.abort();
      this.fetch(logout_url, { method: 'POST', credentials: 'include' });
    };
    const q = new NotificationQueue();

    fetchEventSource(`${fileUrl}/changes`, {
      headers,
      onmessage: async (msg) => {
        switch (msg.event) {
          case 'checking':
            this.numberOfFilesBeingSaved++;
            q.push(this.notify.info(this.intl.t('collaboration.status.checking', { filename: basename(file.path) }), { closeAfter: null }), 3_000);
            break;

          case 'blocked':
            this.numberOfFilesBeingSaved--;
            q.push(this.notify.error(this.intl.t('collaboration.status.blocked', { filename: basename(file.path) }), { closeAfter: 7_000 }), 7_000);
            break;

          case 'modified':
            try {
              q.push(this.notify.info(this.intl.t('collaboration.status.saving', { filename: basename(file.path) }), { closeAfter: null }), 3_000);
              const resp = await this.fetch(fileUrl, {
                headers,
              });
              const data = await resp.blob();
              const source = new FileReader(tBoxClient, data, file.name);
              await tBoxClient.file.upload(file.spaceId, file.path, source);
              await this.fetch(`${fileUrl}/saved`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  ...headers,
                },
              });
            } finally {
              this.numberOfFilesBeingSaved--;
              q.push(null);
            }
            break;
        }
      },
      signal: ctrl.signal,
      openWhenHidden: true,
    });
    return new Session(editor_url, editor, cleanup);
  }

  get shouldPromptBeforeUnload() {
    return this.isEditorOpened || this.numberOfFilesBeingSaved > 0;
  }

  async authorizationHeaders() {
    const sigma = await tBoxClient.session.getSigmaSession();
    return {
      Authorization: 'Cryptonuage-SIGMA sigma_session_id="' + sigma.id + '"',
    };
  }

  async fetch(resource, options) {
    const req = new Request(resource, options);
    const resp = await fetch(req).catch((e) => {
      throw {
        message: this.intl.t('collaboration.errors.connection'),
        details: `${req.method} ${req.url}: ${e.toString()}`,
      };
    })
    if (resp.status === 401 || resp.status === 403) {
      throw {
        title: this.intl.t('collaboration.errors.forbidden.title'),
        message: this.intl.t('collaboration.errors.forbidden.message'),
        details: `${req.method} ${req.url}: ${resp.status} ${resp.statusText}`,
      };
    }
    if (!resp.ok) {
      throw {
        title: this.intl.t('collaboration.errors.other.title'),
        message: this.intl.t('collaboration.errors.other.message'),
        details: `${req.method} ${req.url}: ${resp.status} ${resp.statusText}`,
      };
    }
    return resp;
  }

  trackingError() {
    return {
      message: this.intl.t('collaboration.errors.tracking'),
    }
  }

  httpResponseReadingError(method, url, resp, error) {
    return {
      title: this.intl.t('collaboration.errors.other.title'),
      message: this.intl.t('collaboration.errors.other.message'),
      details: `${method} ${url}: ${resp.status} ${resp.statusText}: ${error.toString()}`,
    };
  }
}

class Session {
  url;
  closed = false;

  #editor;
  #cleanup;

  constructor(url, editor, cleanup) {
    this.url = url;
    this.#editor = editor;
    this.#cleanup = cleanup;
  }

  async close(options = { force: false }) {
    // Refuse to close twice (in particular, do not force close when already closed, as this will abort the grace period).
    if (this.closed) {
      return true;
    }
    if (options.force) {
      const closed = await this.#editor.close({ force: true });
      this.#cleanup();
      this.closed = closed;
      return closed;
    }
    const closed = await this.#editor.close();
    if (!closed) {
      return false;
    }
    // Grace period, allow Thinkfree to push the modified file.
    setTimeout(() => this.#cleanup(), GRACE_PERIOD);
    this.closed = true;
    return true;
  }
}

class ThinkfreeEditor {
  app;
  origin;
  loaded = false;
  closed = false;

  #events;
  #disconnect;

  constructor(app, origin) {
    this.app = app;
    this.origin = origin;
    this.#events = new EventTarget();
  }

  async connect() {
    const onmessage = (event) => {
      if (event.origin === this.origin) {
        const { ThinkfreeWeboffice: message } = JSON.parse(event.data);
        // console.debug(message);
        const e = new Event('message');
        e.origin = event.origin;
        e.message = message;
        this.#events.dispatchEvent(e);
        this.#handleEvent(e);
      }
    }
    window.addEventListener('message', onmessage);
    this.#disconnect = () => {
      window.removeEventListener('message', onmessage);
    };
  }

  async close(options = { force: false }) {
    if (this.closed) {
      return true;
    }
    if (!this.loaded || options.force) {
      this.#disconnect();
      this.closed = true;
      return true;
    }
    return new Promise((resolve) => {
      let id;
      this.#events.addEventListener('message', (event) => {
        const message = event.message;
        if (message.type === 'result' && message.method === 'App.close' && message.id === id) {
          const succeed = message.data === 'succeed';
          if (succeed) {
            this.#disconnect();
            this.closed = true;
          }
          resolve(succeed);
        }
      });
      id = this.#postMessage({ method: "App.close" });
    });
  }

  #postMessage(message) {
    message.app = this.app;
    message.version = "v1.0";
    message.type = message.type || "cmd";
    message.params = message.params || [];
    message.id = `${nextID++}`; // Must be a string.
    const iframe = document.getElementById('online_editor_iframe');
    const receiver = iframe?.contentWindow;
    const envelope = { ThinkfreeWeboffice: message };
    const data = JSON.stringify(envelope); // Must be serialized (!).
    // console.debug('postMessage', message);
    receiver?.postMessage(data, this.origin);
    return message.id;
  }

  #handleEvent(event) {
    const message = event.message;
    if (message.type === 'event' && message.method === 'App.loaded') {
      this.loaded = true;
    }
    if (message.type === 'event' && message.method === 'App.closed') {
      this.closed = true;
    }
  }
}

// Ensure the notification is visible until the next notification is pushed and at least for the specified delay.
// The last notification must have its own closeAfter configured or you may push a null message to flush it (it will be closed after the delay specified when it was pushed to the queue).
class NotificationQueue {
  #current;

  push(message, delay) {
    const current = this.#current;
    if (current) {
      const elapsed = new Date() - current.time;
      if (elapsed >= current.delay) {
        current.message.close();
      } else {
        setTimeout(() => {
          current.message.close();
        }, current.delay - elapsed);
      }
    }
    if (message) {
      this.#current = { message, delay, time: new Date() };
    }
  }
}

// GPT-4o (via duck.ai)
function basename(path) {
  return path.split('/').pop().split('\\').pop(); // Handles both Unix and Windows paths
}