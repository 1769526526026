// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr } from '@ember-data/model';

// The server settings.
// See ServerSettingsCollection.
export default class extends Model {
  // Antivirus URL. Default is empty (no antivirus).
  // @type string
  @attr av;
  
  // Available features.
  // @type Array<ServerCapability>
  @attr capabilities;
  
  // DLP URL. Default is empty (no DLP).
  // @type string
  @attr dlp;
  
  // Deposit box settings. Optional.
  // @type DepositBoxSettings
  @attr depositBox;
  
  // True if direct and group conversations are disabled.
  // @type boolean
  @attr disableConversations;
  
  // True if the sharing links should not contain the key.
  // @type boolean
  @attr disableCreatingSharingLinkIncludingKey;
  
  // True if sharing links are disabled.
  // @type boolean
  @attr disableSharingLinks;
  
  // Entity tag.
  // @type string
  @attr eTag;
  
  // Deprecated. Use UserStatusRecord's EnforcedTrustee.
  // @type string
  @attr enforcedTrustee;
  
  // Localized URL for EULA. The key is a language code ("en", "fr").
  // @type Record<string, string>
  @attr eulaUrl;
  
  // Deprecated.
  // @type number
  @attr fileSharePeriod;
  
  // Email notification frequency for new messages in minutes.
  // @type number
  @attr messagingEmailNotificationFrequency;
  
  // Minimal number of owners per workspace. Optional.
  // @type MinimalNumberOfOwnersPerWorkspace
  @attr minimalNumberOfOwnersPerWorkspace;
  
  // Password complexity rules. If not null, they override ServerSettings's PasswordStrength. See ValidationModule's GetPasswordScore().
  // @type PasswordComplexity
  @attr passwordComplexity;
  
  // Required password strength. See also ServerSettings's PasswordComplexity. See ValidationModule's GetPasswordScore().
  // @type number
  @attr passwordStrength;
  
  // Platform customization. Optional. If present, all the fields are set.
  // @type PlatformCustomization
  @attr platformCustomization;
  
  // Localized URL for Privacy Policy. The key is a language code ("en", "fr").
  // @type Record<string, string>
  @attr privacyPolicyUrl;
  
  // Number of days before a deleted file or workspace is removed from the server, null if not defined. If ServerCapabilityRetentionDelay is present, null means that the delay is not configured (infinite retention). If ServerCapabilityRetentionDelay is absent, the setting is not exposed publicly and this field is always null.
  // @type int|null
  @attr retentionDelayDays;
  
  // Default duration for a sharing link, in days. 0 if undefined.
  // @type number
  @attr sharingLinkDefaultPeriod;
  
  // Maximum duration for a sharing link, in days. 0 if undefined.
  // @type number
  @attr sharingLinkMaxPeriod;
  
  // Support email address, may be empty.
  // @type string
  @attr supportEmail;
  
  // Idle timeout before logout on the web (in minutes). 0 if disabled.
  // @type number
  @attr webIdleLogoutTimeoutMinutes;
  
  // Online collaboration.
  // @type boolean
  @attr enableOnlineCollaboration;
  
  // Online collaboration editor URL.
  // @type string
  @attr collaborationAdapterUrl;
  
  // Server URL.
  // @type string
  @attr serverUrl;
}

