import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';

export default class FileEditorComponent extends Component {
    @service intl;
    @service notify;
    @service thinkfree;

    @tracked status; // 'loading' -> 'loaded' -> 'closing' -> 'closed'
    @tracked session;

    constructor() {
        super(...arguments);
        (async () => {
            try {
                this.status = 'loading';
                this.session = await this.thinkfree.open(this.args.file);
                this.status = 'loaded';
            } catch (error) {
                this.notify.error(error.message, {
                    title: error.title,
                    error: error,
                });
                setInterval(() => this.close(), 500);
            }
        })();
    }

    willDestroy() {
        // Do not force close the editor if it is already closed.
        // In theory, this would abort the grace period (in practice, the session will refuse to force close if it is already closed).
        if (!this.session?.closed) {
            this.session?.close({ force: true });
        }
        this.session = null;
        this.thinkfree.isEditorOpened = false;
    }

    get isLoading() {
        return this.status === 'loading';
    }

    get isClosing() {
        return this.status === 'closing';
    }

    get size() {
        const { contentSize: bytes } = this.args.file;

        // This code is already duplicated, so just add one more copy here.
        // Note that the version in UploadItemComponent seems to be better than the others.
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 B';
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)));
        return Math.round(bytes / Math.pow(1000, i), 2) + ' ' + sizes[i];
    }

    get modified() {
        const file = this.args.file;
        const author = file.author;
        return this.intl.t('documents.fileViewer.modified', {
            date: DateTime.fromISO(file.lastModified).toLocaleString(
                DateTime.DATETIME_SHORT,
            ),
            user: `${author.givenName} ${author.surname} (${author.email})`,
        });
    }

    get editorURL() {
        return this.session?.url;
    }

    @action
    async close() {
        switch (this.status) {
            case 'loading':
                this.status = 'closed';
                this.args.onClose?.();
                break;

            case 'loaded': {
                this.status = 'closing';
                const closed = await this.session?.close();
                if (closed) {
                    this.status = 'closed';
                    this.args.onClose?.();
                } else {
                    this.status = 'loaded';
                }
                break;
            }
        }
    }
}
