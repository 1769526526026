// Code generated by "libcryptobox/cmd/cryogen"; DO NOT EDIT.
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

// A space.
// See SpaceCollection.
export default class extends Model {
  // Kind.
  // @type SpaceKind
  @attr kind;
  
  // Name.
  // @type string
  @attr name;
  
  // Description.
  // @type string
  @attr description;
  
  // Tags.
  // @type Array<string>
  @attr tags;
  
  // Status.
  // @type SpaceStatus
  @attr status;
  
  // Email notification for the space conversation.
  // @type boolean
  @attr messagingEmailNotification;
  
  // Hash of the thumbnail image. Empty if the space has not thumbnail. See SpaceCollection's GetThumbnail().
  // @type string
  @attr thumbnailHash;
  
  // Online edition.
  // @type boolean
  @attr allowCollaboration;
  
  // Number of members.
  // @type number
  @attr memberCount;
  
  // Number of files.
  // @type number
  @attr fileCount;
  
  // Total size of files in the latest revision, in bytes. This includes the encryption header (12 bytes per file chunk).
  // @type number
  @attr rawSize;
  
  // Role of the authenticated user in the space.
  // @type Role
  @attr userRole;
  
  // Last modification date.
  // @type string
  @attr date;
  
  // True if the space belongs to the favorites category.
  // @type boolean
  @attr isFavorite;
  
  // Rights of the authenticated user.
  // @type Array<SpaceAccessRight>
  @attr accessRights;
  
  @hasMany('security-level', { async: false, inverse: null }) securityLevels;
  @hasMany('category', { async: true, inverse: null }) categories;
  @belongsTo('user', { async: false, inverse: null }) peer;
  @belongsTo('file-download-subscription', { async: true, inverse: null }) fileDownloadSubscription;
  @hasMany('file-sharing', { async: true, inverse: null }) fileSharings;
  @belongsTo('file-subscription', { async: true, inverse: null }) fileSubscription;
  @hasMany('message', { async: true, inverse: null }) messages;
  @hasMany('notification', { async: true, inverse: null }) notifications;
  @belongsTo('read-cursor', { async: true, inverse: null }) readCursor;
  @belongsTo('permissions', { async: true, inverse: null }) permissions;
  @hasMany('space-member', { async: true, inverse: 'space' }) members;
  @belongsTo('sync-state', { async: true, inverse: null }) syncState;
}

