import { inject as service } from '@ember/service';
import Component from "@glimmer/component";
import { tBoxClient } from 'client/initializers/init-toolbox';

export default class CollaborationFormOverviewComponent extends Component {
    @service account;

    get visible() {
        return this.account.serverSettings.enableOnlineCollaboration && this.account.status.canUseOnlineCollaboration && this.args.space.status === 'active';
    }

    get checked() {
        return this.args.space.allowCollaboration;
    }

    set checked(value) {
        tBoxClient.space.setAllowCollaboration(this.args.space.id, value);
    }

    get disabled() {
        return !this.args.space.isAdmin;
    }
}
