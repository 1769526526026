import { computed } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { tBoxClient } from 'client/initializers/init-toolbox';
import { task, timeout } from 'ember-concurrency';
import getNotifyRightChange from '../utils/getNotifyRightChange';
import { DEVICE_KEY_ASSOCIATION_MODAL_NAME, ENFORCED_TRUSTEES_MODAL_NAME } from '../utils/enums';
import shouldRenderEnforcedTrusteesModal from '../utils/shouldRenderEnforcedTrusteesModal';
import PrivateFileWriter from 'client/toolbox/private-file-writer';
import isAccountRegisteredInDevice from '../utils/isAccountRegisteredInDevice';
import shouldRenderWarningNoTrusteeModal from '../utils/shouldRenderWarningNoTrusteeModal';
import { clearStorage } from '../utils/clearStorage';
import { tracked } from '@glimmer/tracking';
import { observes } from '@ember-decorators/object';
import { confirm } from '@tauri-apps/plugin-dialog';
import { listen } from '@tauri-apps/api/event';
import { invoke } from '@tauri-apps/api/core';
/* global ipc */
/* global libcryptobox */

const { InviteExtensionRequest, MessagingEmailNotification, Messaging } =
  libcryptobox.ServerCapability;
const LOGOUT_SOURCE_KEY = 'logoutSource';

export default class Account extends Service {
  isLoggedIn = false;
  @tracked isLoggingIn = false;
  @tracked credentialError = false;
  @tracked serverSettings = null;
  @tracked loginError = false;
  @tracked requestedHash = '';
  @tracked enforcedTrustee = null;
  @tracked enforcedTrusteeInviter = null;
  @tracked enforcedTrusteesStatus = null;
  @tracked status = null;
  @tracked userEmail = '';
  @tracked userFirstname = '';
  @tracked userLastname = '';
  @tracked personalSpaceId = '';
  @tracked personalCert = '';
  @tracked isInPersonalSpace = false;
  @tracked trustees = null;
  @tracked trusteeWarningModalIsOpen = false;
  @tracked validationError = false;
  @tracked newUser = false;
  @tracked favoritesCategoryId = null;
  @tracked hasLocalChanges = false;
  @tracked modalToOpen = [];
  // PREVENT add modal ENFORCED_TRUSTEES_MODAL_NAME
  // ON Guest Enforced Trustee + Admin Enforced Trustee
  // WHEN {enforcedTrusteeStatus: 'ok', inviteeTrusteeStatus: 'additionRequired'}
  // Ref JIRA bug : https://jira-sec.ercom.fr/browse/CNUAGE-11011
  @tracked runningEnforcedTrustee = false;
  @tracked lockModalsFlow = false;
  @tracked platformConfig = null;
  @tracked _cancelLogin = null;

  @service router;
  @service commonService;
  @service notify;
  @service intl;
  @service store;
  @service modalManager;
  @service messaging;
  @service('transfers') transfersManager;
  @service notificationApi;
  @service session;
  @service connection;
  @service preaddMember;
  @service userActivity;
  @service platformCustomization;
  @service('deposit-box') depositBoxService;
  @service thinkfree;

  constructor() {
    super(...arguments);
    void this.initialize();
  }

  async initialize() {
    const transfersManager = this.transfersManager;
    const thinkfree = this.thinkfree;
    if (this.connection.isElectronApp) {
      window.onbeforeunload = () => {
        if (this.hasLocalChanges || transfersManager.isTransfering || thinkfree.shouldPromptBeforeUnload) {
          return this._onBeforeOnloadElectronModal();
        }
      };
    } else if (this.connection.isTauriApp) {
      // NB. You must enable confirm_on_exit in Tauri, check src-tauri/lib.rs.
      // The option is enabled in css/client, not in cbox-front.
      listen('exit-requested', async () => {
        if (this.hasLocalChanges || transfersManager.isTransfering || thinkfree.shouldPromptBeforeUnload) {
          const intl = this.intl;
          const quit = await confirm(intl.t('desktop.quit.message'), {
            title: intl.t('desktop.quit.title'),
            kind: 'warning',
            okLabel: intl.t('desktop.quit.buttonQuit'),
            cancelLabel: intl.t('desktop.quit.buttonCancel'),
          });
          if (quit) {
            invoke('exit');
          }
        } else {
          invoke('exit');
        }
      });
    } else {
      window.onbeforeunload = () => {
        if (this.hasLocalChanges || transfersManager.isTransfering || thinkfree.shouldPromptBeforeUnload) {
          return "Local changes wasn't save, are you sure to leave this tab";
        }
      };
    }

    window.addEventListener('unload', function () {
      PrivateFileWriter.removeAll();
    });

    // In the web plateforme,
    // the client is initialized AND calls itself the user session AND init the Toolbox with it.
    // In the desktop client,
    // the Toolbox handle its own database and is able to know if the user is logged in before the client
    //
    // For user session
    // Web : js client => Toolbox
    // Desktop : Toolbox => js client
    //
    // In the Web plateforme, this fonction ("tBoxClient.isLoggedIn()") will be called before the session has been init.
    // So, in case of a refresh && user already logged in, this will be "false"
    //
    // Conclusion : Can be "true" ONLY in desktop client
    if (await tBoxClient.isLoggedIn()) {
      const accountInfos = await tBoxClient.account.getInfo();
      this.setUserInfos(accountInfos);
      this.set('isLoggedIn', true);
    }
    // We bypass the store because it is unloaded on logout and we would lost the loginStatus record.
    // ie. `this.get('store').queryRecord('login-status', { adapterOptions: { subscribe: this } })` would be more idiomatic but would not update after a logout.
    await tBoxClient.loginStatus.watch({
      onQueryResultUpdate: async (id, loginStatus) => {
        if (loginStatus.isLoggedIn) {
          //HACK Ensure the trustees are loaded before isLoggedIn is set.
          //This does update the user status twice (see login).
          if (!this.messaging.isEnabled) {
            await this.messaging.initializeMessaging();
          }

          const currentIsLoggedIn = this.isLoggedIn;

          if (this.connection.isDesktopApp && this.platformConfig?.isEnabled) {
            this._addModal('platform-customization-modal');
          }

          await this.loadUserStatus(true);

          // We use the temp variable because "isLoggedIn" is updated in "this.loadUserStatus"
          // And we keep that order because it will correspond to the modal appearance order
          // TODO : order modals before they are print instead of ordering them in that flow
          if (!currentIsLoggedIn) {
            await this.handleDeviceRegisteredModal();
          }

          this.onLoginModals();
        }
        this.set('isLoggedIn', loginStatus.isLoggedIn);
      },
      onQueryError: (_, error) => {
        if (error.code !== libcryptobox.ErrorCode.LoggedOut) {
          console.warn('onQueryError', 'loginStatus', error);
        }
      },
    });

    // We could use the store and subscribe to user-status/trustee on login, but we would have to refactor `loadUserStatus()`.
    // (JBE) ...and I don't remember why.
    await tBoxClient.userStatus.watch({
      onQueryResultUpdate: async (_, userStatus) => {
        if (!this.isLoggedIn) {
          return;
        }

        const status = await tBoxClient.trustee.getStatus();
        const { enforcedTrusteeStatus } = status;
        if (
          enforcedTrusteeStatus ===
          libcryptobox.EnforcedTrusteeStatus.ReplacementRequired
        ) {
          this._addModal(ENFORCED_TRUSTEES_MODAL_NAME);
          this.openNextModal();
        }
        await this.loadUserStatus();

        const { enforcedTrustee } = userStatus;
        if (enforcedTrustee) {
          const enforcedTrusteeResult =
            await tBoxClient.user.match(enforcedTrustee);
          this.set('enforcedTrustee', enforcedTrusteeResult[0]);
        }
      },
      onQueryError: (_, error) => {
        if (error.code !== libcryptobox.ErrorCode.LoggedOut) {
          console.warn('onQueryError', 'userStatus', error);
        }
      },
    });

    await tBoxClient.trustee.watchAll({
      onQueryResultUpdate: (_, trustees) => {
        this.set('trustees', trustees);
      },
      onQueryError: (_, error) => {
        if (error.code !== libcryptobox.ErrorCode.LoggedOut) {
          console.warn('onQueryError', 'trustee', error);
        }
      },
    });

    await tBoxClient.userAgentStatus.watch({
      onQueryResultUpdate: (_, status) => {
        this.set('hasLocalChanges', status.hasLocalChanges);
      },
      onQueryError: (_, error) => {
        console.warn('onQueryError', 'userAgentStatus', error);
      },
    });
    this.set(
      'favoritesCategoryId',
      await tBoxClient.category.getFavoritesCategoryId(),
    );

    await tBoxClient.serverSettings.watch({
      onQueryResultUpdate: (_, settings) => {
        const { capabilities } = settings;

        const capabilitiesFlags = new Map();
        capabilitiesFlags.set('hasExtensionRequest', InviteExtensionRequest);
        capabilitiesFlags.set(
          'hasMessagingEmailNotification',
          MessagingEmailNotification,
        );
        capabilitiesFlags.set('hasMessaging', Messaging);

        capabilitiesFlags.forEach((value, key) => {
          settings[key] = capabilities.includes(value);
        });

        this.set('serverSettings', settings);
        this.set('connection.serverUrl', settings.serverUrl);

        if (!this.isLoggedIn) {
          return;
        }

        //init messaging and load ws
        this.messaging.initializeMessaging();
      },
      onQueryError: (_, error) => {
        console.warn('onQueryError', 'serverSettings', error);
      },
    });

    // CNUAGE-13168 Observe the user account.
    tBoxClient.account.watchCurrent({
      onQueryResultUpdate: async () => {
        const accountInfos = await tBoxClient.account.getInfo();
        this.setUserInfos(accountInfos);
      },
      onQueryError: () => {},
    });

    this.platformCustomization.getConfigFromUrl(
      this.connection.serverAddress,
      (config) => {
        this.set('platformConfig', config);
      },
    );
  }

  @observes('isLoggedIn')
  onLog() {
    //logout
    if (!this.isLoggedIn) {
      if (!this.connection.isDesktopApp) {
        this.userActivity.stop();
      }

      const session = this.session;
      const messaging = this.messaging;
      messaging.set('messagingIsClosed', true);
      messaging.set('messagingIsOpened', false);
      messaging.set('messagingIsOpening', false);
      messaging.set('hideOfflineBanner', true);
      clearStorage({
        toKeep: [
          'listDisplay',
          'displayFilters',
          'sortingWS',
          'roleWS',
          'tagsFilterWS',
          'nameFilterWS',
          'scrollPositionWS',
        ],
        storageType: 'sessionStorage',
      });
      session.clearSession();
      this.set('isLoggingIn', false);
      this.set('status', null);
      this.set('newUser', false);
      this.setUserInfos(null, { clear: true });
    } else {
      //login
      this.clearLogoutSource();

      if (!this.connection.isDesktopApp) {
        this.userActivity.start();
      }

      if (this.requestedHash) {
        window.location.hash = this.requestedHash;
        this.set('requestedHash', '');
      } else {
        this.router.transitionTo('my-groups');
      }
      // Reload the messaging to get the new server settings. if user is logged in
      // For Fix CNUAGE-13751
      this.messaging.initializeMessaging();
    }
  }

  @task({ enqueue: true })
  *_manageUserStatus(status, enforcedTrusteeEmail, accountInfos) {
    yield timeout(1000);

    if (
      status === libcryptobox.EnforcedTrusteeStatus.CleanUpRequired ||
      status === libcryptobox.EnforcedTrusteeStatus.RemovalRequired
    ) {
      yield tBoxClient.trustee.resolveStatus();
      const trustees = yield tBoxClient.trustee.list();

      if (trustees.length === 0) {
        this._addModal('trustee-warning');
        this.set('trusteeWarningModalIsOpen', true);
      }

      return;
    }

    const enforcedNotHimself = enforcedTrusteeEmail !== accountInfos.user.email;
    const enforcedTrusteeModalOpen = this.modalManager.isModalOpen(
      ENFORCED_TRUSTEES_MODAL_NAME,
    );

    if (enforcedNotHimself && !enforcedTrusteeModalOpen) {
      this.set(
        'newUser',
        status === libcryptobox.EnforcedTrusteeStatus.AdditionRequired,
      );
      this._addModal(ENFORCED_TRUSTEES_MODAL_NAME);
    }
  }

  @computed('userFirstname', 'userLastname')
  get userCompleteName() {
    return `${this.userFirstname} ${this.userLastname}`;
  }

  loadSession(sessionObject) {
    this.cancelLogin();
    this.set('isLoggingIn', true);
    const promise = tBoxClient.session.load(sessionObject);
    this._cancelSession = promise.cancel;
    return promise
      .then(() => this.loadUserStatus())
      .then(() => setTimeout(() => this.set('isLoggingIn', false), 3000))
      .catch((error) => {
        this.set('isLoggingIn', false);
        throw error;
      });
  }

  login(email, pwd) {
    if (email && pwd) {
      this.set('credentialError', false);
      this.set('isLoggingIn', true);
      this.set('email', email);

      const server = this.connection.serverAddress;
      const promise = tBoxClient.login(server, email, pwd);
      this._cancelLogin = promise.cancel;

      return promise
        .then(() => {
          this._cancelLogin = null;
          const session = this.session;
          return session.setSession();
        })
        .then(() => this.loadUserStatus(true))
        .then(() => this.isLoggedIn)
        .catch((error) => {
          this._cancelLogin = null;
          this.set('isLoggedIn', false);
          this.set('isLoggingIn', false);
          if (error.code === libcryptobox.ErrorCode.UnvalidatedEmail) {
            this.set('validationError', true);
            return;
          }
          if (error.code === libcryptobox.ErrorCode.InvalidCredentials) {
            this.set('credentialError', true);
            return;
          }
          this.set('loginError', true);
        });
    } else {
      this.set('credentialError', true);
    }
  }

  cancelLogin() {
    if (this._cancelLogin) {
      this._cancelLogin();
    }
    this._cancelLogin = null;
    if (this._cancelSession) {
      this._cancelSession();
    }
    this._cancelSession = null;
  }

  logout(source) {
    if (source) {
      this.setLogoutSource(source);
    }

    if (this.hasLocalChanges) {
      const confirmLogout = confirm(this.intl.t('logoutWarning'));
      if (confirmLogout && this.hasLocalChanges) {
        this.confirmLogout();
      }
    } else {
      this.confirmLogout();
    }
  }

  confirmLogout() {
    if (this.isLoggedIn) {
      tBoxClient.logout();
      clearStorage({
        toKeep: [
          'listDisplay',
          'displayFilters',
          'sortingWS',
          'roleWS',
          'tagsFilterWS',
          'nameFilterWS',
          'scrollPositionWS',
        ],
        storageType: 'sessionStorage',
      });
      this.clearAccountInfos();
      PrivateFileWriter.removeAll();
      // this.router.transitionTo('login')
    }
  }

  async getNewEmail(token) {
    try {
      return await tBoxClient.account.getEmailUpdateInfo(token);
    } catch (error) {
      if (error.code === libcryptobox.ErrorCode.InvalidCredentials) {
        this.set('credentialError', true);
      }
      return null;
    }
  }

  async confirmChangeEmail(token, newEmail) {
    try {
      await tBoxClient.account.validateEmailUpdate(token, newEmail);
      return true;
    } catch (error) {
      this.notify.error(error.message, {
        title: error.title,
        closeAfter: null,
        error: error,
      });
      return false;
    }
  }

  _addModal(name, position) {
    if (this.modalToOpen.indexOf(name) > -1) {
      return;
    }
    if (this.runningEnforcedTrustee && name === ENFORCED_TRUSTEES_MODAL_NAME) {
      return;
    }
    if (typeof position !== 'number') {
      this.modalToOpen.push(name);
    } else {
      this.modalToOpen.splice(position, 0, name);
    }
  }

  //call once after login
  onLoginModals() {
    this.openNextModal();
  }

  async closeModal(modalName) {
    this.modalToOpen.removeObject(modalName);
    if (this.modalManager.isModalOpen(modalName)) {
      await this.modalManager.close(modalName);
    }
  }

  openNextModal() {
    if (!this.lockModalsFlow && this.modalToOpen.length > 0) {
      const modal = this.modalToOpen[0];
      if (modal === ENFORCED_TRUSTEES_MODAL_NAME) {
        this.runningEnforcedTrustee = true;
      }
      if (!this.modalManager.isModalOpen(modal)) {
        this.modalManager.open(modal);
      }
    }
  }

  toggleLockModalsFlow(toggle) {
    this.lockModalsFlow = toggle;
  }

  onCloseModals(modalName) {
    this.runningEnforcedTrustee = false;
    this.modalToOpen = this.modalToOpen.filter((modal) => modal !== modalName);
    this.onLoginModals();
  }

  clearAccountInfos() {
    this.set('isLoggedIn', false);
    this.set('status', null);
    this.set('trustees', null);
    this.set('messaging.isEnabled', false);
    this.setUserInfos(null, { clear: true });
    const transfersManager = this.transfersManager;
    transfersManager.get('queued').clear();
    transfersManager.get('active').clear();
    transfersManager.get('completed').clear();
    transfersManager.get('failed').clear();
  }

  setUserInfos(accountInfos, { clear = false } = {}) {
    const { user, personalSpaceId } = accountInfos || {};

    this.set('userEmail', clear ? '' : user.email);
    this.set('userFirstname', clear ? '' : user.givenName);
    this.set('userLastname', clear ? '' : user.surname);
    this.set('personalCert', clear ? '' : user.signingCertificate);
    this.set('personalSpaceId', clear ? '' : personalSpaceId);
  }

  async handleDeviceRegisteredModal() {
    if (this.connection.isDesktopApp) {
      const personalCert = this.personalCert;
      const accountsRegistered = await tBoxClient.account.list();
      const serverUrl = this.connection.serverAddress?.toLowerCase();

      const isRegistered = isAccountRegisteredInDevice(
        serverUrl,
        personalCert,
        accountsRegistered,
      );

      if (!isRegistered) {
        this._addModal(DEVICE_KEY_ASSOCIATION_MODAL_NAME);
      }
    }
  }

  async handleTrusteesWarningModal(status, checkTrustee) {
    await tBoxClient.account.getInfo();

    const trusteeList = (await tBoxClient.trustee.list()) || [];
    this.set('trustees', trusteeList);

    const shouldOpenModal = shouldRenderWarningNoTrusteeModal(
      this.trustees.length,
      status,
      checkTrustee,
      ['trustee-warning', ENFORCED_TRUSTEES_MODAL_NAME],
      this.modalManager,
    );

    if (shouldOpenModal) {
      this._addModal('trustee-warning', 1);
      this.set('trusteeWarningModalIsOpen', true);
    }
  }

  async isOnDevice() {
    let account;
    try {
      await this.store.queryRecord('device', {});
      account = await this.store.queryRecord('account', {});
    } catch (e) {
      console.warn(e);
    }

    return account?.isOnDevice;
  }

  handlePersonalSpace(hasPersonalSpace, personalSpaceId) {
    const currentUserStatus = this.status;
    if (
      !hasPersonalSpace &&
      hasPersonalSpace !== currentUserStatus.personalSpace &&
      personalSpaceId
    ) {
      const personalSpaceRecord = this.store.peekRecord({
        type: 'space',
        id: personalSpaceId,
      });
      if (personalSpaceRecord) {
        personalSpaceRecord.unloadSpace();
      }
    }
  }

  /**
   * Controls the behavior of modals in relation to user email changes.
   * Disables modals when the user is changing their email address or during specific account recovery steps.
   * @returns {Array} The updated list of modals to be displayed.
   */
  handleEmailChangeModalControl() {
    const currentRouteName = this.router.currentRouteName;
    const currentURL = this.router.currentURL;
    const excludedModals = [
      'validatePreadd',
      'trustee-warning',
      ENFORCED_TRUSTEES_MODAL_NAME,
    ];

    if (
      currentRouteName === 'account-recovery.rendezvous.depositary' ||
      currentURL.startsWith('/change-email')
    ) {
      this.userActivity.stop();
      this.modalToOpen = this.modalToOpen.filter(
        (modal) => !excludedModals.includes(modal),
      );
      return this.modalToOpen;
    }
  }

  async loadUserStatus(checkTrustee = false) {
    const userStatus = await this.store.queryRecord('user-status', {});
    const currentUserStatus = this.status;
    const accountInfos = await tBoxClient.account.getInfo();

    if (currentUserStatus) {
      this.handlePersonalSpace(
        userStatus.hasPersonalSpace,
        accountInfos.personalSpaceId,
      );

      const notifyRightChange = getNotifyRightChange(
        currentUserStatus,
        userStatus,
      );
      if (notifyRightChange) {
        const message = this.intl.t('toasts.rightsUpdated.message');
        const title = this.intl.t('toasts.rightsUpdated.title');

        this.notify.info(message, { title });
      }
    }
    await this.depositBoxService.checkCanAccessDepositBox();

    // redirect to my-groups if user can't access deposit box
    //TODO find a better way to handle this
    const depositBoxRoutesNames = [
      'deposit-boxes.index',
      'deposit-boxes.deposit-box',
    ];
    if (
      !this.depositBoxService.canAccessDepositBox &&
      depositBoxRoutesNames.includes(this.router.currentRouteName)
    ) {
      this.router.transitionTo('my-groups');
    }

    this.set('status', {
      admin: userStatus.isAdmin,
      state: userStatus.state,
      maximumLevel: userStatus.maximumLevel,
      personalSpace: userStatus.hasPersonalSpace,
      createSpace: userStatus.canCreateSpace,
      canInvite: userStatus.canInviteUsers,
      setInviteRights: userStatus.canSetInviteRights,
      canWrite: userStatus.canWrite,
      canUseOnlineCollaboration: userStatus.canUseOnlineCollaboration,
    });

    if (accountInfos) {
      this.setUserInfos(accountInfos);
      if (!this.isLoggedIn) {
        this.set('isLoggedIn', true);
      }
      /**
       * Check if the current route is not an account recovery rendezvous page or any page after it,
       * then loads pre-added members if they exist and shows a modal to validate them.
       */
      if (
        !/^account-recovery\.rendezvous(\..*)?$/.test(
          this.router.currentRouteName,
        )
      ) {
        await this.preaddMember.loadPreaddedMembers();
        if (this.preaddMember.preaddedMembers.length > 0) {
          this._addModal('validatePreadd');
        }
      }

      // ENFORCED TRUSTEES (enforced trustee + inviter)
      const status = await tBoxClient.trustee.getStatus();
      let enforcedTrusteeResult = [];
      let inviterResult = [];

      if (userStatus.enforcedTrustee) {
        enforcedTrusteeResult = await tBoxClient.user.match(
          userStatus.enforcedTrustee,
        );
      }

      if (userStatus.inviter?.email) {
        inviterResult = await tBoxClient.user.match(userStatus.inviter.email);
      }

      this.set('enforcedTrusteesStatus', status);
      this.set('enforcedTrusteeInviter', inviterResult[0]);
      this.set('enforcedTrustee', enforcedTrusteeResult[0]);

      const isOnDevice = await this.isOnDevice();
      if (!isOnDevice && shouldRenderEnforcedTrusteesModal(status)) {
        const { enforcedTrusteeStatus } = status;
        await this._manageUserStatus.perform(
          enforcedTrusteeStatus,
          userStatus.enforcedTrustee,
          accountInfos,
        );
      }

      await this.handleTrusteesWarningModal(status, checkTrustee);

      this.handleEmailChangeModalControl();
    }
  }

  _onBeforeOnloadElectronModal() {
    const intl = this.intl;
    const returnCode = ipc.electron.dialog.showMessageBoxSync({
      title: intl.t('desktop.quit.title'),
      message: intl.t('desktop.quit.message'),
      buttonQuit: intl.t('desktop.quit.buttonQuit'),
      buttonCancel: intl.t('desktop.quit.buttonCancel'),
    });

    if (returnCode === 0) {
      // user has clicked on quit
      return null;
    }
    return 'Do not close';
  }

  getLogoutSource() {
    return localStorage.getItem(LOGOUT_SOURCE_KEY);
  }

  isUserLogOutSource() {
    return this.getLogoutSource() === 'user';
  }

  isTimeoutActivityLogOutSource() {
    return this.getLogoutSource() === 'timeout-activity';
  }

  setLogoutSource(source) {
    localStorage.setItem(LOGOUT_SOURCE_KEY, source);
  }

  clearLogoutSource() {
    localStorage.removeItem(LOGOUT_SOURCE_KEY);
  }
}
